/*
 * Copyright 2019, 2022 by Avid Technology, Inc.
 */

export default () => {
    const ua = window.navigator.userAgent.toLowerCase();
    const chromeLength = 'chrome/'.length;
    const indexChrome = ua.indexOf('chrome/');
    const versionStr = ua.substr(indexChrome + chromeLength, 3);
    const chromeVersion = parseInt(versionStr, 10);
    return {
        isChrome: indexChrome > -1,
        chromeVersion,
    };
};
