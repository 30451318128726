/*
 * Copyright 2019 by Avid Technology, Inc.
 */

export const adminUrl = '/admin';

export default () => {
    if (window.location.pathname.startsWith(adminUrl)) {
        window.location.replace('/');
    } else {
        window.location.replace(adminUrl);
    }
};
