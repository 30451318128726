/*
 * Copyright 2020-2021 by Avid Technology, Inc.
 */

import styles from './styles.scss';
import languages from '../../../../../configs/languages.json';
import localizationStorage from '../../localization/storage';
import createStylesElement from '../createStylesElement';
import { getPosition, arrowPosition } from '../calculations';
import Navigation from './navigation';

const SELECT_BUTTON_ID = 'select-button';
const DROPDOWN_ID = 'dropdown-container';
const DROPDOWN_ARROW = 'dropdown-arrow';

const clearDropdownPosition = () => {
    const dropdown = document.getElementById(DROPDOWN_ID);
    dropdown.classList.remove(styles['bottom-dropdown-class'], styles['top-dropdown-class']);

    const arrow = document.getElementById(DROPDOWN_ARROW);
    arrow.classList.remove(styles['top-arrow'], styles['bottom-arrow']);

    [
        'bottom',
        'left',
        'maxHeight',
        'maxWidth',
        'minHeight',
        'minWidth',
        'right',
        'top',
    ].forEach((key) => {
        dropdown.style[key] = null;
    });
};

const updateDropdownPosition = () => {
    const button = document.getElementById(SELECT_BUTTON_ID);
    const buttonRect = button.getBoundingClientRect();

    const position = getPosition(buttonRect);
    const { arrowClass, dropdownClass, arrowLeft } = arrowPosition(buttonRect, position);

    const dropdown = document.getElementById(DROPDOWN_ID);
    dropdown.classList.add(styles[dropdownClass]);

    const arrow = document.getElementById(DROPDOWN_ARROW);
    arrow.classList.add(styles[arrowClass]);
    arrow.style.left = `${arrowLeft}px`;

    Object.entries(position).forEach(([key, value]) => {
        dropdown.style[key] = `${value}px`;
    });
};

const createButton = () => {
    const selectButton = createStylesElement({
        el: 'button',
        classNames: [SELECT_BUTTON_ID, styles['select-button']],
        id: SELECT_BUTTON_ID });

    selectButton.setAttribute('type', 'button');
    selectButton.setAttribute('aria-haspopup', 'listbox');

    selectButton.addEventListener('click', toggleDropdown);

    const globeIcon = createStylesElement({ classNames: [styles['globe-icon']] });

    const langLabel = createStylesElement({ el: 'p', classNames: [styles['language-label']] });
    langLabel.textContent = localizationStorage.getLocale();

    selectButton.append(globeIcon, langLabel);

    return selectButton;
};

const createDropdown = () => {
    const dropdownContainer = createStylesElement({
        classNames: [DROPDOWN_ID, styles['dropdown-container'], styles.hide],
        id: DROPDOWN_ID,
    });

    const arrow = createStylesElement({ id: DROPDOWN_ARROW });

    const list = createStylesElement({
        el: 'ul',
        classNames: [styles.dropdown],
        id: 'language-dropdown-list'
    });

    list.setAttribute('role', 'listbox');
    list.setAttribute('aria-labelledby', SELECT_BUTTON_ID);

    const options = languages.map((lang) => {
        const { value, label } = lang;
        const option = createStylesElement({ el: 'li', classNames: [styles.option] });
        option.setAttribute('role', 'option');
        option.setAttribute('tabindex', '-1');
        option.dataset.value = value;

        const language = document.createElement('div');
        language.textContent = label;

        const locale = createStylesElement({ classNames: [styles.locale] });
        locale.textContent = value;

        option.appendChild(language);
        option.appendChild(locale);
        return option;
    });

    list.append(...options);

    dropdownContainer.append(arrow, list);

    return {
        dropdown: dropdownContainer,
        list,
    };
};

const showDropdown = () => {
    const dropdown = document.getElementById(DROPDOWN_ID);
    dropdown.classList.remove(styles.hide);
    updateDropdownPosition();
};

const hideDropdown = () => {
    const dropdown = document.getElementById(DROPDOWN_ID);
    dropdown.classList.add(styles.hide);
    clearDropdownPosition();
};

const toggleDropdown = () => {
    const dropdown = document.getElementById(DROPDOWN_ID);
    const button = document.getElementById(SELECT_BUTTON_ID);
    if (dropdown.classList.contains(styles.hide)) {
        showDropdown();
        button.setAttribute('aria-expanded', 'true');
    } else {
        hideDropdown();
        button.removeAttribute('aria-expanded');
    }
};

const onOutsideClick = (event) => {
    if (event.target.closest(`.${SELECT_BUTTON_ID}`)) {
        return;
    }
    if (!event.target.closest(`.${DROPDOWN_ID}`)) {
        hideDropdown();
    }
};

const onEscPressed = (event) => {
    const { keyCode } = event;
    const ESC_KEY_CODE = 27;
    if (keyCode === ESC_KEY_CODE) {
        hideDropdown();
    }
};

function createSelectorButton() {
    const container = createStylesElement({ classNames: [styles['language-container'], 'language-container'] });
    const button = createButton();

    container.append(button);

    const { dropdown, list } = createDropdown();
    document.getElementById('login-page').appendChild(dropdown);

    return { container, button, dropdown, list };
}

function onListClick(event, callback) {
    const option = event.target.closest('li');
    const { value } = option.dataset;
    callback(value);
}

function onListKeyDown(event, callback) {
    const { keyCode } = event;
    const ENTER_KEY_CODE = 13;
    if (keyCode === ENTER_KEY_CODE) {
        event.preventDefault();
        const { value } = event.target.dataset;
        callback(value);
    }
}

function addListeners(list, callback) {
    const onListClickWrapper = event => onListClick(event, callback);
    const onListKeyDownWrapper = event => onListKeyDown(event, callback);
    list.addEventListener('click', onListClickWrapper);
    list.addEventListener('keydown', onListKeyDownWrapper);
    window.addEventListener('resize', hideDropdown);
    document.addEventListener('click', onOutsideClick);
    document.addEventListener('keydown', onEscPressed);
    document.addEventListener('scroll', hideDropdown);

    return () => {
        list.removeEventListener('click', onListClickWrapper);
        list.removeEventListener('keydown', onListKeyDownWrapper);
        window.removeEventListener('resize', hideDropdown);
        document.removeEventListener('click', onOutsideClick);
        document.removeEventListener('keydown', onEscPressed);
        document.removeEventListener('scroll', hideDropdown);
    };
}

function createLanguageSelector(onChange) {
    const { container, button, dropdown, list } = createSelectorButton();
    const navigation = new Navigation({ button, dropdown });
    const removeListeners = addListeners(list, onChange);
    return {
        getElement() {
            return container;
        },
        focus() {
            button.focus();
        },
        destroy() {
            removeListeners();
            navigation.unregisterEvents();
            container.remove();
        }
    };
}

export {
    createLanguageSelector,
};

