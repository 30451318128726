/*
 * Copyright 2018, 2021 by Avid Technology, Inc.
 */
import { put } from './http-requests';
import { getSaveSettingsPath } from './path';

export default (value, kind, key, masterRegion) => {
    if (!value) {
        return Promise.reject(new Error('[IAM settings] No value specified'));
    }
    if (!masterRegion) {
        return Promise.reject(new Error('[IAM settings] No master region specified.'));
    }
    const url = getSaveSettingsPath(kind, key);
    const body = {
        entity: { value, masterRegion }
    };
    return put(url, body);
};
