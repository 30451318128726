/*
 * Copyright 2020 by Avid Technology, Inc.
 */

import getApplicationMode from '../module-loader/main/load-modules/application-mode';

export default () => {
    if (window.AV.internal.mode) {
        return;
    }
    const appMode = getApplicationMode();

    window.AV.internal = window.AV.internal || {};
    window.AV.internal.embeddedIn = appMode.embeddedIn;
    window.AV.internal.mode = appMode.mode;
};
