/*
 * Copyright 2018-2021 by Avid Technology, Inc.
 */

import logoutCall from './logout-call';
import { SIGN_OUT_KEY } from '../../constants';

if (!window.AV) {
    window.AV = {};
}

window.AV.LoginProvider = window.AV.LoginProvider || {};
window.AV.internal = window.AV.internal || {};

window.AV.LoginProvider.logout = (href) => {
    return logoutCall()
        .catch((err) => {
            console.warn('Logout call is failed', err);
        })
        .then(() => {
            window.localStorage.setItem(SIGN_OUT_KEY, new Date());
            window.location.href = href || '/';
        });
};

window.AV.LoginProvider.endSession = () => {
    return logoutCall()
        .catch((err) => {
            console.warn('Logout call is failed', err);
        }).finally(() => {
            window.localStorage.setItem(SIGN_OUT_KEY, new Date());
        });
};

window.AV.internal.getShowCredentialsAreaToggle = () => {
    return localStorage.getItem('avid-unsafe-show-login-area-with-okta') === 'true';
};

window.AV.internal.showCredentialsAreaWithOkta = () => {
    localStorage.setItem('avid-unsafe-show-login-area-with-okta', 'true');
    console.log('UNSAFE! For debug purposes only.');
};

window.AV.internal.hideCredentialsAreaWithOkta = () => {
    localStorage.setItem('avid-unsafe-show-login-area-with-okta', 'false');
};
