/*
 * Copyright 2018 by Avid Technology, Inc.
 */

const defaultSettings = {
    LOCALE: 'en',
    DATE_STYLE: 'DMY',
    TIME_ZONE: 'system'
};

export default defaultSettings;
