/*
 * Copyright 2017, 2019-2021 by Avid Technology, Inc.
 */

import { get } from './http-requests';
import {
    getLinkByBaseData,
    getLinksByType,
    getLocale,
    getReferencingItemsLink,
    getSimpleLink,
} from './utils';

/**
 * Asset class.
 * Asset class provides API for working with assets.
 * getByBaseData - returns asset by base data.
 * getLinks - returns all available asset-by-id links.
 */
export default class Asset {
    constructor(config) {
        this.asset = config.asset;
    }

    /**
     * Returns asset by base data.
     * @param {Object} baseData Asset base data.
     * @param {String} baseData.systemID Location item system id.
     * @param {String} baseData.systemType Location item system type.
     * @param {String} baseData.id Asset id.
     * @param {Object} queryParams Object that contains query params (key: value)
     * @param {AbortSignal} signal - optional - for abort signal
     * @returns {Promise} Asset.
     */
    static getByBaseData({ baseData, queryParams, signal }) {
        return this.getLinks().then((links) => {
            let link = getLinkByBaseData(links, baseData);
            if (!link || !link.href) {
                return Promise.reject({
                    errorMessage: 'Link aa:asset-by-id do not exist'
                });
            }
            link = link.href.replace('{id}', encodeURIComponent(baseData.id));
            return get(link, queryParams, signal);
        });
    }

    /**
     * Returns true if resource has loc:referencing-items link
     * @returns {Boolean}
     */
    hasReferencingItemsLink() {
        const link = getReferencingItemsLink(this.asset);
        return Boolean(link);
    }

    /**
     * Returns loc:referencing-items resource
     * @param {Object} queryParams params to be added to a request
     * @returns {Promise} Referencing item
     */
    getReferencingItems(queryParams = {}) {
        if (!this.hasReferencingItemsLink()) {
            return Promise.reject({
                errorMessage: 'Link loc:referencing-items do not exist'
            });
        }
        const link = getReferencingItemsLink(this.asset);
        return get(link, queryParams);
    }

    /**
     * Returns asset-by-id links with information about system.
     * @returns {Promise} Array with asset-by-id links.
     */
    static getLinks() {
        return getLinksByType('aa:asset-by-id');
    }

    /**
     * @param - optional - {AbortSignal} [config.signal] abort signal
     * @param - optional - {Array} [config.attributeNames] attributes separated by comma.
     * @returns {Promise} attributes data.
     */
    getAttributes({ signal, attributeNames }) {
        const link = getSimpleLink(this.asset, 'aa:attributes');
        if (!link) {
            return Promise.reject({
                errorMessage: 'Link aa:attributes do not exist'
            });
        }
        const lang = getLocale();
        const queryParams = { lang };
        if (attributeNames) {
            queryParams.attributes = attributeNames.join();
        }
        return get(link, queryParams, signal);
    }
}
