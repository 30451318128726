/*
 * Copyright 2018, 2020-2022 by Avid Technology, Inc.
 */

import { getLoginErrors } from '../messages';
import localizationStorage from '../localization/storage';

const isErrorType = (errorType, { code, error }) => {
    return errorType.error
        ? errorType.error === error
        : errorType.code === code;
};

const getFailureMessage = (status, data) => {
    const { code, error, params, message } = data;
    let errors = Object.values(getLoginErrors()).filter(it => it.status === status);

    if (params && params.reason) {
        errors = errors.filter(it => it.params && it.params.reason === params.reason);
    }

    const errorToShow = errors.find(it => isErrorType(it, { code, error }));
    console.warn('Login failed', status, data);

    if (!errorToShow && message) {
        return message;
    }

    return (errorToShow && errorToShow.message) || localizationStorage.getLocalization('generic-login-error');
};

export default getFailureMessage;

