/*
 * Copyright 2018, 2021 by Avid Technology, Inc.
 */

export const BASE_SETTINGS_PATH = '/apis/avid.iam;version=3;realm=global/principals/self/settings';

export const getSaveSettingsPath = (kind, key) => `${BASE_SETTINGS_PATH}?kind=${kind}&key=${key}`;

export const getSettingsPath = (kind, key) => {
    const params = `?entity=${encodeURIComponent(JSON.stringify({ kind, key }))}`;
    return `${BASE_SETTINGS_PATH}${params}`;
};
