/*
 * Copyright 2018-2019, 2021 by Avid Technology, Inc.
 */

import styles from './styles/eula.scss';
import localizationStorage from '../../login/localization/storage';
import { brandingProfiles } from '../../constants';

function createStylesElement({ el = 'div', classNames, id }) {
    const element = document.createElement(el);
    element.classList.add(...classNames);
    if (id) {
        element.setAttribute('id', id);
    }
    return element;
}

function createForm() {
    const form = createStylesElement({
        el: 'form',
        id: 'mcux-eula-form',
        classNames: [],
    });
    form.style.display = 'none';

    const licenseAgreementText = createStylesElement({
        classNames: ['license-agreement-text', styles['license-agreement-text']],
        id: 'license-agreement-text',
    });
    licenseAgreementText.setAttribute('data-ready', 'false');

    const submitContainer = createStylesElement({
        el: 'p',
        classNames: ['mcux-paragraph', styles['mcux-paragraph']],
    });

    const submitButton = createStylesElement({
        el: 'input',
        classNames: ['mcux-submit-button', styles['mcux-submit-button']],
    });
    submitButton.type = 'submit';
    submitButton.value = localizationStorage.getLocalization('accept-license-agreement');

    submitContainer.appendChild(submitButton);

    form.appendChild(licenseAgreementText);
    form.appendChild(submitContainer);

    return form;
}

function creatLoading() {
    const loadingContainer = createStylesElement({
        classNames: ['mcux-loading-outer', styles['mcux-loading-outer']],
        id: 'mcux-eula-loading-outer',
    });

    const loadingInnerContainer = createStylesElement({
        classNames: ['mcux-loading-inner', styles['mcux-loading-inner']],
        id: 'mcux-eula-loading-inner',
    });
    loadingInnerContainer.innerText = '...';

    loadingContainer.appendChild(loadingInnerContainer);

    return loadingContainer;
}


export default () => {
    const container = createStylesElement({
        classNames: ['eula', styles.eula],
    });

    const logo = createStylesElement({
        classNames: ['logo', styles.logo],
    });

    const formContainer = createStylesElement({
        classNames: ['form-container', styles['form-container']],
    });


    const isMcemProfile = window.AV.internal.profile === brandingProfiles.mcem;
    const titleLogo = isMcemProfile ? 'title-mcem-name-logo' : 'title-avid-name-logo';
    const title = createStylesElement({
        classNames: [titleLogo, styles[titleLogo]],
        id: 'title',
    });


    const logInArea = createStylesElement({
        classNames: ['mcux-area', styles['mcux-area'], 'eula', styles.eula],
    });

    const form = createForm();

    const loading = creatLoading();

    logInArea.appendChild(form);
    logInArea.appendChild(loading);

    formContainer.appendChild(title);
    formContainer.appendChild(logInArea);

    container.appendChild(logo);
    container.appendChild(formContainer);

    return container;
};
