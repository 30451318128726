/*
 * Copyright 2018, 2021 by Avid Technology, Inc.
 */

import localizationStorage from '../../login/localization/storage';
import { DEFAULT_LOCALE } from '../../util/config';

const headers = new Headers();
headers.append('Content-Type', 'text/plain');

const getLicence = (lang) => {
    return fetch(`/license/${lang}/LICENSE.txt`, {
        method: 'get',
        headers,
        credentials: 'same-origin'
    }).then((response) => {
        if (response.status === 200) {
            return response.text();
        }
        throw Error('Failed to get license text.');
    });
};

export default function () {
    const lang = localizationStorage.getLocale();
    return getLicence(lang)
        .catch(() => getLicence(DEFAULT_LOCALE));
}
