/*
 * Copyright 2017-2018 by Avid Technology, Inc.
 */

const GLOBAL_ID_PARTS_COUNT = 4;

const getBase = (globalID) => {
    if (!globalID || typeof globalID !== 'string') {
        console.warn('Wrong globalID', globalID);
        return null;
    }
    const parts = globalID.split(':');
    if (parts.length !== GLOBAL_ID_PARTS_COUNT) {
        console.warn('Wrong globalID', globalID);
        return null;
    }
    const [systemType, systemID, type, id] = parts;
    return {
        systemType, systemID, type, id
    };
};

const getId = (globalID) => {
    const base = getBase(globalID);
    return base && base.id;
};

export default {
    getBase,
    getId
};
