/*
 * Copyright 2021-2022 by Avid Technology, Inc.
 */
export default {
    en: {
        'username-placeholder': 'Username',
        'password-placeholder': 'Password',
        'sign-in-button': 'Sign In',
        'wrong-credentials-error': 'The user name or password you entered is incorrect.',
        'licence-quota-exceeded-error': 'Your MediaCentral | CloudUX Platform licenses are all in use. Please contact your administrator.',
        'user-not-in-group-error': 'The user name or password you entered is incorrect.',
        'licence-seat-entitlement-expired-error': 'Your MediaCentral | CloudUX Platform license expired. Please contact your administrator.',
        'timeout-user-authentication-error': 'The login call timed out. Please try again in a few minutes. Please contact your administrator if the issue persists.',
        'account-locked-or-disabled-error': 'Your account has been locked or disabled. To access your account, please contact your administrator.',
        'sso-sign-in-label': 'Or',
        'okta-sign-in-label': 'Sign in with Okta',
        'sso-sign-in-button': 'Sign in with Microsoft',
        'page-loading-error': 'Error while accessing the back-end server. Please try to reload this page in a minute.',
        'reload-page-message': 'Please reload the page.',
        'reload-button-label': 'Reload',
        'generic-login-error': 'There was a problem signing you in, please try again.',
        'administrator-role': 'Administrator',
        'accept-license-agreement': 'Accept License Agreement',
        'supported-browsers-and-systems-label': 'Supported browsers and operating systems:',
        'supported-browsers-and-systems-list': 'Chrome on Windows and Mac OS',
        'unsupported-browser-error-header': 'Unsupported browser version',
        'unsupported-browser-error-message': "MediaCentral | Cloud UX needs Chrome {0} or later to perform at it's best.",
        'unsupported-operation-system': 'Your operating system is currently not supported by MediaCentral | Cloud UX.',
        'unsupported-operation-system-and-browser': '{0}({1}) is currently not supported by MediaCentral | Cloud UX.',
        'fatal-error-description': 'Error: {0}',
        'fatal-error-generic': 'An error occurred in the application.',
        'fatal-error-access-denied': 'Access Denied!',
        'entitlement-check-error': 'There is no panel license assigned to any of your user groups. Therefore you cannot login to MediaCentral | CloudUX via a panel. Please contact your administrator.',
        'error-page-redirect-to-login-page': 'Go to login page'
    },
    fr: {
        'username-placeholder': "Nom d'utilisateur",
        'password-placeholder': 'Mot de passe',
        'sign-in-button': 'Se connecter',
        'wrong-credentials-error': "Le nom d'utilisateur ou le mot de passe saisis sont incorrects.",
        'licence-quota-exceeded-error': "Vos licences de la plateforme MediaCentral | Cloud UX sont toutes en cours d'utilisation. Veuillez contacter votre administrateur.",
        'user-not-in-group-error': "Le nom d'utilisateur ou le mot de passe saisis sont incorrects.",
        'licence-seat-entitlement-expired-error': 'Votre licence de la plateforme MediaCentral | Cloud UX a expiré. Veuillez contacter votre administrateur.',
        'timeout-user-authentication-error': "L'appel de connexion a expiré. Veuillez réessayer dans quelques minutes. Contactez votre administrateur si le problème persiste.",
        'account-locked-or-disabled-error': 'Votre compte a été verrouillé ou désactivé. Contactez votre administrateur pour accéder à votre compte.',
        'sso-sign-in-label': 'Ou',
        'okta-sign-in-label': 'Sign in with Okta',
        'sso-sign-in-button': 'Connectez-vous avec Microsoft',
        'page-loading-error': "Erreur lors de l'accès au serveur back-end. Veuillez essayer de recharger cette page dans un moment.",
        'reload-page-message': 'Veuillez recharger la page.',
        'reload-button-label': 'Recharger',
        'generic-login-error': 'Il y a eu un problème pour vous inscrire, veuillez réessayer.',
        'administrator-role': 'Administrateur',
        'accept-license-agreement': 'Accepter le contrat de licence',
        'supported-browsers-and-systems-label': "Navigateurs et systèmes d'exploitation pris en charge :",
        'supported-browsers-and-systems-list': 'Chrome sur Windows et Mac OS',
        'unsupported-browser-error-header': 'Version du navigateur non supportée',
        'unsupported-browser-error-message': "MediaCentral | Cloud UX a besoin de Chrome {0} ou d'une version plus récente pour fonctionner au mieux.",
        'unsupported-operation-system': "Votre système d'exploitation n'est actuellement pas pris en charge par MediaCentral | Cloud UX.",
        'unsupported-operation-system-and-browser': "{0}({1}) n'est actuellement pas pris en charge par MediaCentral | Cloud UX.",
        'fatal-error-description': 'Erreur : {0}',
        'fatal-error-generic': "Une erreur s'est produite dans l'application.",
        'fatal-error-access-denied': 'Accès refusé !',
        'entitlement-check-error': 'There is no panel license assigned to any of your user groups. Therefore you cannot login to MediaCentral | CloudUX via a panel. Please contact your administrator.',
        'error-page-redirect-to-login-page': 'Go to login page'
    },
    de: {
        'username-placeholder': 'Benutzername',
        'password-placeholder': 'Kennwort',
        'sign-in-button': 'Anmelden',
        'wrong-credentials-error': 'Benutzername oder Kennwort ist falsch.',
        'licence-quota-exceeded-error': 'Ihre MediaCentral | CloudUX Platform-Lizenzen sind alle in Gebrauch. Bitte wenden Sie sich an Ihren Administrator.',
        'user-not-in-group-error': 'Benutzername oder Kennwort ist falsch.',
        'licence-seat-entitlement-expired-error': 'Ihre MediaCentral | CloudUX Platform-Lizenz ist abgelaufen. Bitte wenden Sie sich an Ihren Administrator.',
        'timeout-user-authentication-error': 'Das Zeitlimit für Ihren Anmeldungsaufruf ist überschritten. Versuchen Sie es in ein paar Minuten erneut. Wenden Sie sich an Ihren Administrator, falls das Problem weiterhin besteht.',
        'account-locked-or-disabled-error': 'Ihr Konto wurde gesperrt oder deaktiviert. Um auf Ihr Konto zuzugreifen, wenden Sie sich bitte an Ihren Administrator.',
        'sso-sign-in-label': 'Oder',
        'okta-sign-in-label': 'Sign in with Okta',
        'sso-sign-in-button': 'Anmelden mit Microsoft',
        'page-loading-error': 'Fehler beim Zugriff auf Backend-Server. Bitte laden Sie diese Seite in einer Minute neu.',
        'reload-page-message': 'Bitte laden Sie diese Seite neu.',
        'reload-button-label': 'Neu laden',
        'generic-login-error': 'Beim Anmelden ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
        'administrator-role': 'Administrator',
        'accept-license-agreement': 'Lizenzvertrag zustimmen',
        'supported-browsers-and-systems-label': 'Unterstützte Browser und Betriebssysteme:',
        'supported-browsers-and-systems-list': 'Chrome auf Windows und Mac OS',
        'unsupported-browser-error-header': 'Nicht unterstützte Browser Version',
        'unsupported-browser-error-message': 'MediaCentral | Cloud UX erfordert Chrome {0} oder neuer, um die beste Benutzererfahrung zu liefern.',
        'unsupported-operation-system': 'Ihr Betriebssystem wird zur Zeit nicht von MediaCentral | Cloud UX unterstützt.',
        'unsupported-operation-system-and-browser': '{0}({1}) wird zur Zeit von MediaCentral | Cloud UX nicht unterstützt.',
        'fatal-error-description': 'Fehler: {0}',
        'fatal-error-generic': 'Ein Fehler ist in der Anwendung aufgetreten. ',
        'fatal-error-access-denied': 'Zugriff verweigert!',
        'entitlement-check-error': 'There is no panel license assigned to any of your user groups. Therefore you cannot login to MediaCentral | CloudUX via a panel. Please contact your administrator.',
        'error-page-redirect-to-login-page': 'Go to login page'
    },
    es: {
        'username-placeholder': 'Nombre de usuario',
        'password-placeholder': 'Contraseña',
        'sign-in-button': 'Iniciar sesión',
        'wrong-credentials-error': 'El nombre de usuario o contraseña es incorrecto.',
        'licence-quota-exceeded-error': 'Tus licencias de MediaCentral | CloudUX Platform están en uso. Ponte en contacto con el administrador.',
        'user-not-in-group-error': 'El nombre de usuario o contraseña es incorrecto.',
        'licence-seat-entitlement-expired-error': 'Tu licencia de MediaCentral | CloudUX Platform ha caducado. Ponte en contacto con el administrador.',
        'timeout-user-authentication-error': 'La llamada de inicio de sesión ha finalizado. Inténtalo nuevamente en unos minutos. Si el problema persiste, ponte en contacto con el administrador.',
        'account-locked-or-disabled-error': 'Tu cuenta ha sido bloqueada o desactivada. Para acceder a la cuenta, ponte en contacto con el administrador.',
        'sso-sign-in-label': 'O',
        'okta-sign-in-label': 'Sign in with Okta',
        'sso-sign-in-button': 'Iniciar sesión con Microsoft',
        'page-loading-error': 'Error al acceder al servidor back-end. Por favor, intente recargar esta página en un minuto.',
        'reload-page-message': 'Por favor, recargue la página.',
        'reload-button-label': 'Recarga',
        'generic-login-error': 'Ha habido un problema al iniciar la sesión, por favor, inténtelo de nuevo.',
        'administrator-role': 'Administrador',
        'accept-license-agreement': 'Aceptar el Acuerdo de Licencia',
        'supported-browsers-and-systems-label': 'Navegadores y sistemas operativos compatibles:',
        'supported-browsers-and-systems-list': 'Chrome en Windows y Mac OS',
        'unsupported-browser-error-header': 'Versión del navegador no compatible',
        'unsupported-browser-error-message': 'MediaCentral | Cloud UX necesita Chrome {0} o una versión posterior para rendir al máximo.',
        'unsupported-operation-system': 'Tu sistema operativo no es actualmente compatible con MediaCentral | Cloud UX.',
        'unsupported-operation-system-and-browser': '{0}({1}) no es actualmente compatible con MediaCentral | Cloud UX.',
        'fatal-error-description': 'Error: {0}',
        'fatal-error-generic': 'Se ha producido un error en la aplicación.',
        'fatal-error-access-denied': 'Acceso denegado.',
        'entitlement-check-error': 'There is no panel license assigned to any of your user groups. Therefore you cannot login to MediaCentral | CloudUX via a panel. Please contact your administrator.',
        'error-page-redirect-to-login-page': 'Go to login page'
    },
    'zh-CN': {
        'username-placeholder': '用户名',
        'password-placeholder': '密码',
        'sign-in-button': '登录',
        'wrong-credentials-error': '输入的用户名或密码不正确。',
        'licence-quota-exceeded-error': '您的 MediaCentral | CloudUX Platform 许可证均在使用中。请联系您的管理员。',
        'user-not-in-group-error': '输入的用户名或密码不正确。',
        'licence-seat-entitlement-expired-error': '您的 MediaCentral | CloudUX Platform 许可证已过期。请联系您的管理员。',
        'timeout-user-authentication-error': '登录调用已超时。请过几分钟再重试。如果问题仍然存在，请与管理员联系。',
        'account-locked-or-disabled-error': '您的帐户已被锁定或禁用。要访问帐户，请联系您的管理员。',
        'sso-sign-in-label': '或者',
        'okta-sign-in-label': 'Sign in with Okta',
        'sso-sign-in-button': '使用Microsoft登录',
        'page-loading-error': '访问后端服务器时出错。 请在一分钟后尝试重新加载此页面。',
        'reload-page-message': '请重新加载页面。',
        'reload-button-label': '刷新',
        'generic-login-error': '登录时出现问题，请重试。',
        'administrator-role': '管理员',
        'accept-license-agreement': '接受许可协议',
        'supported-browsers-and-systems-label': '支持的浏览器和操作系统：',
        'supported-browsers-and-systems-list': 'Chrome Windows版和Mac OS',
        'unsupported-browser-error-header': '不支持的浏览器版本',
        'unsupported-browser-error-message': 'MediaCentral | Cloud UX需要Chrome {0}或更高版本才能发挥最佳性能。',
        'unsupported-operation-system': 'MediaCentral | Cloud UX 当前不支持您的操作系统。',
        'unsupported-operation-system-and-browser': 'MediaCentral | Cloud UX 当前不支持{0}（{1}。',
        'fatal-error-description': '错误：{0}',
        'fatal-error-generic': '应用程序发生错误。',
        'fatal-error-access-denied': '拒绝访问！',
        'entitlement-check-error': 'There is no panel license assigned to any of your user groups. Therefore you cannot login to MediaCentral | CloudUX via a panel. Please contact your administrator.',
        'error-page-redirect-to-login-page': 'Go to login page'
    },
    ja: {
        'username-placeholder': 'ユーザー名',
        'password-placeholder': 'パスワード',
        'sign-in-button': 'サインイン',
        'wrong-credentials-error': '入力したユーザー名またはパスワードが正しくありません。',
        'licence-quota-exceeded-error': 'MediaCentral | CloudUX Platformライセンスはすべて使用されています。管理者に連絡してください。',
        'user-not-in-group-error': '入力したユーザー名またはパスワードが正しくありません。',
        'licence-seat-entitlement-expired-error': 'MediaCentral | CloudUX Platformライセンスは有効期限が切れています。管理者に連絡してください。',
        'timeout-user-authentication-error': 'ログイン呼び出しがタイムアウトしました。数分後にもう一度やり直してください。問題が解決しない場合は、管理者に連絡してください。',
        'account-locked-or-disabled-error': 'アカウントがロックされているか無効になっています。アカウントにアクセスするには、管理者に連絡してください。',
        'sso-sign-in-label': 'または',
        'okta-sign-in-label': 'Sign in with Okta',
        'sso-sign-in-button': 'Microsoftでサインイン',
        'page-loading-error': 'サーバーに接続できません。一分後にこのページを再読み込みして下さい。',
        'reload-page-message': 'ページを再読み込みして下さい。',
        'reload-button-label': '再読み込み',
        'generic-login-error': 'サインイン中エラーが発生しました。もう一度やり直して下さい。',
        'administrator-role': '管理者',
        'accept-license-agreement': '利用規約に同意',
        'supported-browsers-and-systems-label': 'サポートされているブラウザとOS：',
        'supported-browsers-and-systems-list': 'WindowsとmacOSのChrome',
        'unsupported-browser-error-header': '非対応のブラウザバージョン',
        'unsupported-browser-error-message': 'MediaCentral | Cloud UX の最適なエクスペリエンスは Chrome {0} を必要とします。',
        'unsupported-operation-system': 'お使いのOSは現在 MediaCentral | Cloud UX に対応していません。',
        'unsupported-operation-system-and-browser': '{0}({1}) は現在 MediaCentral | Cloud UX に対応していません。',
        'fatal-error-description': 'エラー：{0}',
        'fatal-error-generic': 'アプリケーションでエラーが発生しました。',
        'fatal-error-access-denied': 'アクセスが拒否されました！',
        'entitlement-check-error': 'There is no panel license assigned to any of your user groups. Therefore you cannot login to MediaCentral | CloudUX via a panel. Please contact your administrator.',
        'error-page-redirect-to-login-page': 'Go to login page'
    },
    it: {
        'username-placeholder': 'Nome utente',
        'password-placeholder': 'Password',
        'sign-in-button': 'Accedi',
        'wrong-credentials-error': 'Il nome utente o la password immessi non sono corretti.',
        'licence-quota-exceeded-error': "Le licenze MediaCentral | CloudUX Platform sono tutte in uso. Contattare l'amministratore.",
        'user-not-in-group-error': 'Il nome utente o la password immessi non sono corretti.',
        'licence-seat-entitlement-expired-error': "La licenza MediaCentral | CloudUX Platform è scaduta. Contattare l'amministratore.",
        'timeout-user-authentication-error': "Il tentativo di accesso è scaduto. Riprovare tra qualche minuto. contattare l'amministratore se il problema persiste.",
        'account-locked-or-disabled-error': "L'account è stato bloccato o disattivato. Per accedere all'account, contattare l'amministratore.",
        'sso-sign-in-label': 'O',
        'okta-sign-in-label': 'Sign in with Okta',
        'sso-sign-in-button': 'Accedi con Microsoft',
        'page-loading-error': "Errore durante l'accesso al server back-end. Prova a ricaricare questa pagina tra un minuto.",
        'reload-page-message': 'Ricarica la pagina.',
        'reload-button-label': 'Ricarica',
        'generic-login-error': "Si è verificato un problema durante l'accesso, riprova.",
        'administrator-role': 'Amministratore',
        'accept-license-agreement': 'Accetta il contratto di licenza',
        'supported-browsers-and-systems-label': 'Browser e sistemi operativi supportati:',
        'supported-browsers-and-systems-list': 'Chrome su Windows e Mac OS',
        'unsupported-browser-error-header': 'Versione del browser non supportata',
        'unsupported-browser-error-message': 'MediaCentral | Cloud UX richiede Chrome {0} o versioni successive per funzionare al meglio.',
        'unsupported-operation-system': 'Il tuo sistema operativo non è attualmente supportato da MediaCentral | Cloud UX.',
        'unsupported-operation-system-and-browser': '{0} ({1}) non è attualmente supportato da MediaCentral | Cloud UX.',
        'fatal-error-description': 'Errore: {0}',
        'fatal-error-generic': "Si è verificato un errore nell'applicazione.",
        'fatal-error-access-denied': 'Accesso negato!',
        'entitlement-check-error': 'There is no panel license assigned to any of your user groups. Therefore you cannot login to MediaCentral | CloudUX via a panel. Please contact your administrator.',
        'error-page-redirect-to-login-page': 'Go to login page'
    },
    ar: {
        'username-placeholder': 'اسم المستخدم',
        'password-placeholder': 'كلمة المرور',
        'sign-in-button': 'تسجيل الدخول',
        'wrong-credentials-error': 'اسم المستخدم أو كلمة المرور المدخلان غير صحيحان.',
        'licence-quota-exceeded-error': 'MediaCentral الخاص بك | جميع تراخيص CloudUX Platform قيد الاستخدام. يرجى الاتصال بالمسؤول الخاص بك.',
        'user-not-in-group-error': 'اسم المستخدم أو كلمة المرور المدخلان غير صحيحان.',
        'licence-seat-entitlement-expired-error': 'MediaCentral الخاص بك | انتهت صلاحية ترخيص CloudUX Platform. يرجى الاتصال بالمسؤول الخاص بك.',
        'timeout-user-authentication-error': 'انتهت مهلة تسجيل الدخول. يرجى المحاولة مرة أخرى خلال بضع دقائق. يرجى الاتصال بالمسؤول الخاص بك إذا استمرت المشكلة.',
        'account-locked-or-disabled-error': 'تم قفل حسابك أو تعطيله. يرجى الاتصال بالمسؤول الخاص بك للوصول إلى حسابك.',
        'sso-sign-in-label': 'أو',
        'okta-sign-in-label': 'Sign in with Okta',
        'sso-sign-in-button': 'تسجيل الدخول لمايكروسوفت',
        'page-loading-error': 'خطأ أثناء محاولة الوصول إلى السيرفر. يرجى محاولة إعادة تحميل هذه الصفحة خلال دقيقة واحدة.',
        'reload-page-message': 'يرجى إعادة تحميل الصفحة.',
        'reload-button-label': 'إعادة تحميل',
        'generic-login-error': 'هناك مشكلة في تسجيل الدخول، يرجى المحاولة مرة أخرى.',
        'administrator-role': 'المدير',
        'accept-license-agreement': 'قبول إتفاقية الإستخدام',
        'supported-browsers-and-systems-label': 'المتصفحات وأنظمة التشغيل المدعومة:',
        'supported-browsers-and-systems-list': 'Chrome on Windows and Mac OS',
        'unsupported-browser-error-header': 'متصفح - إصدار غير مدعوم',
        'unsupported-browser-error-message': 'MediaCentral| Cloud UX – Chrome {0} يتطلب متصفح مشابه أو إصدار أحدث لأداء أفضل ',
        'unsupported-operation-system': 'نظام التشغيل الذي تستخدمه غير مدعوم حاليًا من MediaCentral | Cloud UX',
        'unsupported-operation-system-and-browser': '{0} ({1}) غير مدعوم حاليًا من MediaCentral | Cloud UX',
        'fatal-error-description': 'خطأ : {0}',
        'fatal-error-generic': 'حدث خطأ في التطبيق.',
        'fatal-error-access-denied': 'غير مسموح بالدخول!',
        'entitlement-check-error': 'There is no panel license assigned to any of your user groups. Therefore you cannot login to MediaCentral | CloudUX via a panel. Please contact your administrator.',
        'error-page-redirect-to-login-page': 'Go to login page'
    },
    tr: {
        'username-placeholder': 'Kullanıcı adı',
        'password-placeholder': 'Parola',
        'sign-in-button': 'Oturum Aç',
        'wrong-credentials-error': 'Girdiğiniz kullanıcı adı veya parola hatalı.',
        'licence-quota-exceeded-error': 'MediaCentral | CloudUX Platform lisanslarının tamamı kullanımda. Lütfen yöneticinizle iletişime geçin.',
        'user-not-in-group-error': 'Girdiğiniz kullanıcı adı veya parola hatalı.',
        'licence-seat-entitlement-expired-error': 'MediaCentral | CloudUX Platform lisansının süresi doldu. Lütfen yöneticinizle iletişime geçin.',
        'timeout-user-authentication-error': 'Giriş araması zaman aşımına uğradı. Lütfen birkaç dakika içinde tekrar deneyin. Sorun devam ederse lütfen yöneticinizle iletişime geçin.',
        'account-locked-or-disabled-error': 'Hesabınız kilitlendi veya devre dışı bırakıldı. Hesabınıza erişmek için lütfen yöneticinizle iletişime geçin.',
        'sso-sign-in-label': 'Or',
        'okta-sign-in-label': 'Sign in with Okta',
        'sso-sign-in-button': 'Sign in with Microsoft',
        'page-loading-error': 'Error while accessing the back-end server. Please try to reload this page in a minute.',
        'reload-page-message': 'Please reload the page.',
        'reload-button-label': 'Reload',
        'generic-login-error': 'There was a problem signing you in, please try again.',
        'administrator-role': 'Administrator',
        'accept-license-agreement': 'Accept License Agreement',
        'supported-browsers-and-systems-label': 'Supported browsers and operating systems:',
        'supported-browsers-and-systems-list': 'Chrome on Windows and Mac OS',
        'unsupported-browser-error-header': 'Unsupported browser version',
        'unsupported-browser-error-message': "MediaCentral | Cloud UX needs Chrome {0} or later to perform at it's best.",
        'unsupported-operation-system': 'Your operating system is currently not supported by MediaCentral | Cloud UX.',
        'unsupported-operation-system-and-browser': '{0}({1}) is currently not supported by MediaCentral | Cloud UX.',
        'fatal-error-description': 'Error: {0}',
        'fatal-error-generic': 'An error occurred in the application.',
        'fatal-error-access-denied': 'Access Denied!',
        'entitlement-check-error': 'There is no panel license assigned to any of your user groups. Therefore you cannot login to MediaCentral | CloudUX via a panel. Please contact your administrator.',
        'error-page-redirect-to-login-page': 'Go to login page'
    },
    'pt-PT': {
        'username-placeholder': 'Nome de utilizador',
        'password-placeholder': 'Palavra-passe',
        'sign-in-button': 'Iniciar Sessão',
        'wrong-credentials-error': 'O nome de utilizador ou palavra-passe que introduziu está incorreto.',
        'licence-quota-exceeded-error': 'O seu MediaCentral | As licenças da CloudUX Platform estão todas em utilização. Contacte o administrador.',
        'user-not-in-group-error': 'O nome de utilizador ou palavra-passe que introduziu está incorreto.',
        'licence-seat-entitlement-expired-error': 'O seu MediaCentral | A licença da CloudUX Platform expirou. Contacte o administrador.',
        'timeout-user-authentication-error': 'O início de sessão excedeu o tempo limite. Tente novamente em alguns minutos. Contacte o administrador se o problema persistir.',
        'account-locked-or-disabled-error': 'A sua conta foi bloqueada ou desativada. Para aceder à sua conta, contacte o administrador.',
        'sso-sign-in-label': 'Ou',
        'okta-sign-in-label': 'Sign in with Okta',
        'sso-sign-in-button': 'Conectar-se com a Microsoft',
        'page-loading-error': 'Erro no acesso ao servidor back-end. Por favor, tente recarregar esta página num instante.',
        'reload-page-message': 'Por favor, volte a carregar a página.',
        'reload-button-label': 'Recarregar',
        'generic-login-error': 'Houve um problema no registo, por favor tente novamente.',
        'administrator-role': 'Administrador',
        'accept-license-agreement': 'Aceitar o acordo de licença',
        'supported-browsers-and-systems-label': 'Navegadores e sistemas operativos suportados :',
        'supported-browsers-and-systems-list': 'Chrome em Windows e Mac OS',
        'unsupported-browser-error-header': 'Versão do navegadore não suportada',
        'unsupported-browser-error-message': 'MediaCentral | Cloud UX precisa de Cromo {0} ou superior para que funcione optimamente.',
        'unsupported-operation-system': 'O seu sistema operativo não é actualmente suportado pelo MediaCentral | Cloud UX.',
        'unsupported-operation-system-and-browser': '{0}({1})) não é actualmente suportado pelo MediaCentral | Cloud UX.',
        'fatal-error-description': 'Erro: {0}',
        'fatal-error-generic': 'Ocorreu um erro no pedido.',
        'fatal-error-access-denied': 'Acesso negado!',
        'entitlement-check-error': 'There is no panel license assigned to any of your user groups. Therefore you cannot login to MediaCentral | CloudUX via a panel. Please contact your administrator.',
        'error-page-redirect-to-login-page': 'Go to login page'
    },
    et: {
        'username-placeholder': 'Kasutajanimi',
        'password-placeholder': 'Parool',
        'sign-in-button': 'Logi sisse',
        'wrong-credentials-error': 'Sisestatud kasutajanimi või parool on vale.',
        'licence-quota-exceeded-error': 'Teie MediaCentral | Kõik platvormi CloudUX litsentsid on kasutusel. Võtke ühendust oma administraatoriga.',
        'user-not-in-group-error': 'Sisestatud kasutajanimi või parool on vale.',
        'licence-seat-entitlement-expired-error': 'Teie MediaCentral | Platvormi CloudUX litsents on aegunud. Võtke ühendust oma administraatoriga.',
        'timeout-user-authentication-error': 'Sisselogimise aeg aegus. Proovige mõne minuti pärast uuesti. Võtke ühendust oma adminstraatoriga, kui probleem ei lahene.',
        'account-locked-or-disabled-error': 'Teie konto on lukustatud või keelatud. Kontole pääsemiseks pöörduge oma administraatori poole.',
        'sso-sign-in-label': 'Or',
        'okta-sign-in-label': 'Sign in with Okta',
        'sso-sign-in-button': 'Sign in with Microsoft',
        'page-loading-error': 'Error while accessing the back-end server. Please try to reload this page in a minute.',
        'reload-page-message': 'Please reload the page.',
        'reload-button-label': 'Reload',
        'generic-login-error': 'There was a problem signing you in, please try again.',
        'administrator-role': 'Administrator',
        'accept-license-agreement': 'Accept License Agreement',
        'supported-browsers-and-systems-label': 'Supported browsers and operating systems:',
        'supported-browsers-and-systems-list': 'Chrome on Windows and Mac OS',
        'unsupported-browser-error-header': 'Unsupported browser version',
        'unsupported-browser-error-message': "MediaCentral | Cloud UX needs Chrome {0} or later to perform at it's best.",
        'unsupported-operation-system': 'Your operating system is currently not supported by MediaCentral | Cloud UX.',
        'unsupported-operation-system-and-browser': '{0}({1}) is currently not supported by MediaCentral | Cloud UX.',
        'fatal-error-description': 'Error: {0}',
        'fatal-error-generic': 'An error occurred in the application.',
        'fatal-error-access-denied': 'Access Denied!',
        'entitlement-check-error': 'There is no panel license assigned to any of your user groups. Therefore you cannot login to MediaCentral | CloudUX via a panel. Please contact your administrator.',
        'error-page-redirect-to-login-page': 'Go to login page'
    },
    ru: {
        'username-placeholder': 'Имя пользователя',
        'password-placeholder': 'Пароль',
        'sign-in-button': 'Войти',
        'wrong-credentials-error': 'Неверное имя пользователя или пароль.',
        'licence-quota-exceeded-error': 'Все лицензии на MediaCentral | CloudUX Platform используются. Свяжитесь со своим администратором.',
        'user-not-in-group-error': 'Неверное имя пользователя или пароль.',
        'licence-seat-entitlement-expired-error': 'Срок действия лицензий на MediaCentral | CloudUX Platform истек. Свяжитесь со своим администратором.',
        'timeout-user-authentication-error': 'Истекло время входа в систему. Повторите попытку через несколько минут. Если проблема не будет устранена, обратитесь к администратору.',
        'account-locked-or-disabled-error': 'Ваша учетная запись была заблокирована или отключена. Для получения доступа к своей учетной записи обратитесь к своему администратору.',
        'sso-sign-in-label': 'Или',
        'okta-sign-in-label': 'Sign in with Okta',
        'sso-sign-in-button': 'Войти через Microsoft',
        'page-loading-error': 'Ошибка при доступе к внутреннему серверу. Пожалуйста, попробуйте перезагрузить эту страницу через минуту.',
        'reload-page-message': 'Пожалуйста, перезагрузите эту страницу.',
        'reload-button-label': 'Перезагрузить',
        'generic-login-error': 'Не удалось войти в систему, попробуйте еще раз.',
        'administrator-role': 'Администратор',
        'accept-license-agreement': 'Принять лицензионное соглашение',
        'supported-browsers-and-systems-label': 'Поддерживаемые браузеры и операционные системы:',
        'supported-browsers-and-systems-list': 'Chrome на Windows и Mac OS',
        'unsupported-browser-error-header': 'Неподдерживаемая версия браузера',
        'unsupported-browser-error-message': 'Для MediaCentral | Cloud UX требуется  Chrome {0} или новее для лучшей производительности .',
        'unsupported-operation-system': 'Ваша операционная система не поддерживается MediaCentral | Cloud UX.',
        'unsupported-operation-system-and-browser': '{0}({1}) не поддерживается MediaCentral | Cloud UX.',
        'fatal-error-description': 'Ошибка: {0}',
        'fatal-error-generic': 'В приложении произошла ошибка.',
        'fatal-error-access-denied': 'Отказано в доступе!',
        'entitlement-check-error': 'There is no panel license assigned to any of your user groups. Therefore you cannot login to MediaCentral | CloudUX via a panel. Please contact your administrator.',
        'error-page-redirect-to-login-page': 'Go to login page'
    },
    ko: {
        'username-placeholder': '사용자 이름',
        'password-placeholder': '패스워드',
        'sign-in-button': '로그인',
        'wrong-credentials-error': '입력된 사용자명 또는 패스워드가 올바르지 않습니다.',
        'licence-quota-exceeded-error': 'MediaCentral | CloudUX Platform 라이센스가 모두 사용 중입니다. 관리자에게 문의하십시오.',
        'user-not-in-group-error': '입력된 사용자명 또는 패스워드가 올바르지 않습니다.',
        'licence-seat-entitlement-expired-error': 'MediaCentral | CloudUX Platform 라이센스가 만료되었습니다. 관리자에게 문의하십시오.',
        'timeout-user-authentication-error': '로그인 호출 시간이 초과되었습니다. 잠시 후 다시 시도하십시오. 문제가 지속되면 관리자에게 문의하십시오.',
        'account-locked-or-disabled-error': '사용자의 계정이 잠겨 있거나 비활성화되었습니다. 계정에 액세스하려면 관리자에게 문의하십시오.',
        'sso-sign-in-label': '또는',
        'okta-sign-in-label': 'Sign in with Okta',
        'sso-sign-in-button': 'Microsoft로 로그인',
        'page-loading-error': '백엔드-서버에 접근하는 동안 오류가 발생했습니다. 잠시 후에 페이지를 새로 고침하세요.',
        'reload-page-message': '페이지를 새로 고침하세요.',
        'reload-button-label': '새로 고침',
        'generic-login-error': '로그인하는 중에 문제가 발생했습니다. 다시 시도하십시오.',
        'administrator-role': '관리자',
        'accept-license-agreement': '라이센스 계약 동의',
        'supported-browsers-and-systems-label': '지원되는 브라우저 및 운영 체제 :',
        'supported-browsers-and-systems-list': 'Windows 와 Mac OS 의 Chrome',
        'unsupported-browser-error-header': '지원되지 않는 브라우저 버전',
        'unsupported-browser-error-message': 'MediaCentral | Cloud UX가 최상의 성능을 발휘하려면 Chrome {0} 이상이 필요합니다.',
        'unsupported-operation-system': 'MediaCentral | Cloud UX를 지원하지 않는 운영체제.',
        'unsupported-operation-system-and-browser': '{0}({1}) 는 MediaCentral | Cloud UX 에서 지원하지 않습니다.',
        'fatal-error-description': '오류 : {0}',
        'fatal-error-generic': '애플리케이션에서 오류가 발생했습니다.',
        'fatal-error-access-denied': '접근 불가!',
        'entitlement-check-error': 'There is no panel license assigned to any of your user groups. Therefore you cannot login to MediaCentral | CloudUX via a panel. Please contact your administrator.',
        'error-page-redirect-to-login-page': 'Go to login page'
    }
};
