/*
 * Copyright 2019 by Avid Technology, Inc.
 */

import SystemsStore from './systemsStore';

export default () => {
    const systemsStore = new SystemsStore();

    const getSystem = ({ systemType, systemID }) =>
        systemsStore.getSystem({ systemType, systemID });

    const exists = ({ systemType, systemID }) =>
        systemsStore.exists({ systemType, systemID });

    const isRemote = ({ systemType, systemID }) =>
        systemsStore.isRemote({ systemType, systemID });

    const getOrigin = ({ systemType, systemID }) =>
        systemsStore.getOrigin({ systemType, systemID });

    const getUrl = ({ systemType, systemID, url }) =>
        systemsStore.getUrl({ systemType, systemID, url });

    const getResourceLink = ({ systemType, systemID, link }) =>
        systemsStore.getResourceLink({ systemType, systemID, link });

    const getServiceRoots = () => systemsStore.getServiceRoots();

    const systems = {
        getSystem,
        exists,
        isRemote,
        getOrigin,
        getUrl,
        getResourceLink,
    };
    const serviceRoots = { get: getServiceRoots };
    return systemsStore.run().then(() => ({ systems, serviceRoots }));
};
