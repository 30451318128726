/*
 * Copyright 2020-2021 by Avid Technology, Inc.
 */

function maxYSpace(spacing, offset, minPadding) {
    return spacing - offset - minPadding;
}

export function getYLocation({ anchor, layer }) {
    const {
        min, max, offset, minPadding
    } = layer;
    const { height, top, bottom } = anchor;
    let align = 'top';
    let maxSpace = maxYSpace(bottom, offset, minPadding);
    const maxFallbackSpace = maxYSpace(top, offset, minPadding);

    if (maxSpace < min && maxFallbackSpace > maxSpace) {
        maxSpace = maxFallbackSpace;
        align = 'bottom';
    }
    const position = anchor[align] + offset + height;
    return {
        minHeight: Math.min(min, maxSpace),
        maxHeight: Math.min(max, maxSpace),
        bottom: align === 'bottom' ? position : null,
        top: align === 'top' ? position : null,
    };
}

function maxXSpace(spacing, size, offset, minPadding) {
    return spacing + size - offset - minPadding;
}

export function getXLocation({
    anchor, layer
}) {
    const {
        min, max, offset, minPadding
    } = layer;
    const { width, right, left } = anchor;
    let align = 'left';
    let maxSpace = maxXSpace(right, width, offset, minPadding);
    const maxFallbackSpace = maxXSpace(left, width, offset, minPadding);

    if (maxSpace < min && maxFallbackSpace > maxSpace) {
        maxSpace = maxFallbackSpace;
        align = 'right';
    }

    let centeringCorrection = ((Math.min(max, maxSpace) - width) / 2);
    if (centeringCorrection > anchor[align]) {
        centeringCorrection = anchor[align];
    }
    const position = anchor[align] + offset - centeringCorrection;
    return {
        minWidth: Math.min(min, maxSpace),
        maxWidth: Math.min(max, maxSpace),
        right: align === 'right' ? position : null,
        left: align === 'left' ? position : null,
    };
}

export const getPosition = (anchorElementRect) => {
    const minWidth = 160;
    const minHeight = 150;
    const maxWidth = 160;
    const maxHeight = Infinity;
    const offsetX = 0;
    const offsetY = 10;
    const marginX = 100;
    const marginY = 50;

    const {
        width, left, right, height, top, bottom
    } = anchorElementRect;

    const heightProps = {
        anchor: {
            height,
            top: top + window.pageYOffset,
            bottom: document.body.clientHeight - (bottom + window.pageYOffset)
        },
        layer: {
            min: minHeight,
            max: maxHeight,
            offset: offsetY,
            minPadding: marginY
        },
    };

    const widthProps = {
        anchor: {
            width,
            left,
            right: window.innerWidth - right
        },
        layer: {
            min: minWidth,
            max: maxWidth,
            offset: offsetX,
            minPadding: marginX
        },
    };

    return {
        ...getYLocation(heightProps),
        ...getXLocation(widthProps),
    };
};

export function arrowPosition(anchorElementRect, position) {
    const ARROW_SIZE = 10;
    const {
        top, bottom, left, right, minWidth
    } = position;
    const { width: anchorWidth, left: anchorLeft } = anchorElementRect;

    let arrowClass;
    let dropdownClass;
    let arrowLeft;
    if (Number.isFinite(top)) {
        arrowClass = 'top-arrow';
        dropdownClass = 'top-dropdown-class';
    } else if (Number.isFinite(bottom)) {
        arrowClass = 'bottom-arrow';
        dropdownClass = 'bottom-dropdown-class';
    }

    if (Number.isFinite(left)) {
        arrowLeft = anchorLeft - left + (anchorWidth / 2) - ARROW_SIZE;
    } else if (Number.isFinite(right)) {
        arrowLeft = anchorLeft - (window.innerWidth - minWidth) + right + (anchorWidth / 2)
            - ARROW_SIZE;
    }

    return {
        arrowClass, dropdownClass, arrowLeft,
    };
}
