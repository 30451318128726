/*
 * Copyright 2018 by Avid Technology, Inc.
 */

export default () => {
    return fetch('/version', {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
            Accept: 'application/json;charset=UTF-8'
        }
    })
        .then((response) => {
            if ((response.status >= 200 && response.status < 300) || response.ok) {
                return Promise.resolve(response.json());
            }
            return Promise.reject(response);
        })
        .catch(() => {
            console.error('Failed to get application version');
            return Promise.reject(new Error('Failed to get application version'));
        });
};
