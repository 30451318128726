/*
 * Copyright 2018, 2020-2021 by Avid Technology, Inc.
 */

import updateUserSettings from './updateUserSettings';
import initGeneralSystemSettings from './initGeneralSystemSettings';
import initUserSettings from '../settings/initUserSettings';
import localizationStorage from '../login/localization/storage';
import { USER_LOCALE_KEY } from '../constants';
import isAdminLoginCheck from '../login/admin/isAdminLoginCheck';

export default (generalSettings, userSettings) => {
    return new Promise((resolve) => {
        initGeneralSystemSettings(generalSettings);
        if (isAdminLoginCheck()) {
            resolve(userSettings);
        }
        const loginLocale = localizationStorage.getLocale();
        if (userSettings.locale !== loginLocale) {
            localStorage.setItem(USER_LOCALE_KEY, loginLocale);
            const nextSettings = {
                ...userSettings,
                locale: loginLocale,
            };
            return updateUserSettings(nextSettings)
                .then((settings) => {
                    resolve(settings);
                })
                .catch((error) => {
                    localStorage.setItem(USER_LOCALE_KEY, userSettings.locale);
                    console.warn('User Settings update failed. Use previous settings. Error:', error);
                    resolve(userSettings);
                });
        }
        localStorage.setItem(USER_LOCALE_KEY, userSettings.locale || loginLocale);
        resolve(userSettings);
    }).then((userSetting) => {
        initUserSettings(userSetting, isAdminLoginCheck());
    }).catch((error) => {
        console.warn(`Initializing settings has failed with error: ${error}.`);
        AV.internal.generalSystemSettings = {};
        AV.internal.userSettings = {};
    });
};
