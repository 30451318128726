/*
 * Copyright 2018, 2021 by Avid Technology, Inc.
 */
/**
 * @author anna.kryzhko
 */

import { getFatalErrorDom, getAccessDeniedErrorDom } from './getFatalErrorDom';
import localizationStorage from '../login/localization/storage';

const loadFatalErrorStyles = () => {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = '/core/css/fatal-error.css';
    document.head.appendChild(link);
};

const handleFatalError = (err) => {
    console.error(err);
    let text = (err && typeof err === 'object') ? err.message : err;
    text = text
        ? localizationStorage.getLocalization('fatal-error-description', text)
        : localizationStorage.getLocalization('fatal-error-generic');
    document.body.innerHTML = '';
    loadFatalErrorStyles();
    document.body.appendChild(getFatalErrorDom(text));
};
const handleAccessDeniedError = (err) => {
    console.error(err);
    let text = (err && typeof err === 'object') ? err.message : err;
    text = text
        ? localizationStorage.getLocalization('fatal-error-description', text)
        : localizationStorage.getLocalization('fatal-error-access-denied');
    document.body.innerHTML = '';
    loadFatalErrorStyles();
    document.body.appendChild(getAccessDeniedErrorDom(text));
};

export {
    handleFatalError,
    handleAccessDeniedError,
};
