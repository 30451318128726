/*
 * Copyright 2018, 2021 by Avid Technology, Inc.
 */

function status(response) {
    if ((response.status >= 200 && response.status < 300) || response.ok) {
        return Promise.resolve(response.json());
    }
    if (response.status === 401) {
        return Promise.reject(new Error('[IAM setting] Unauthorized access'));
    }
    if (response.status === 404) {
        return Promise.resolve(null);
    }
    return Promise.reject(response);
}

function get(path) {
    return fetch(path, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'same-origin'
    })
        .then(status);
}

function put(path, body) {
    return fetch(path, {
        method: 'put',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'same-origin',
        body: JSON.stringify(body)
    })
        .then(status);
}

export { get, put };
