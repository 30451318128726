/*
 * Copyright 2018-2021 by Avid Technology, Inc.
 */
import { AV } from './av';
import '../../login/main/login-provider';
import { handleAccessDeniedError, handleFatalError } from '../../util/fatal-error';
import validateLocation from './validateLocation';
import initLoginPromise from '../../login/main/init-login-promise';
import initEulaPromise from '../../eula/main/initEulaPromise';
import { MINIMAL_CHROME_VERSION, showChromeVersionError, } from './chromeVersionError';
import browserInfo from './browserInfo';
import setPageTitle from './setPageTitle';
import getInitConfiguration from '../../util/getInitConfiguration';
import initSettings from '../../settings/initSettings';
import {
    initAVBuildVersion,
    initAVUser,
    initSystemsStore,
    saveIamTokenData,
    setCustomerProfile
} from './utils';
import initMode from '../../mode/init';
import getApplicationVersion from '../../util/application-version';

const ACCESS_DENIED_CODE = 403;
const ACCESS_DENIED_MESSAGE = 'accessDenied';

AV.internal = AV.internal || {};

/* eslint-disable consistent-return */
export default () => {
    const { isChrome, chromeVersion } = browserInfo();

    if (isChrome && (chromeVersion < MINIMAL_CHROME_VERSION)) {
        showChromeVersionError();
        return;
    }

    const initialize = () => {
        return getInitConfiguration().then((initInfo) => {
            const {
                isLoggedIn,
                customerProfile,
                identityProviders,
                errorCode,
            } = initInfo;
            setCustomerProfile(customerProfile);
            setPageTitle();
            initMode();

            if (errorCode === ACCESS_DENIED_CODE) {
                return Promise.reject(new Error(ACCESS_DENIED_MESSAGE));
            }

            if (!isLoggedIn) {
                return initLoginPromise(identityProviders);
            }

            return Promise.resolve(initInfo);
        });
    };

    const initVersionAndEula = (initInfo) => {
        const getEulaInfo = initInfo ? () => Promise.resolve(initInfo) : getInitConfiguration;
        return Promise.all([getApplicationVersion(), getEulaInfo()])
            .then(([{ version, build }, extendedInitInfo]) => {
                const { userTokenData } = extendedInitInfo;
                saveIamTokenData(userTokenData);
                initAVBuildVersion({ version, build });
                const { eulaSettings } = extendedInitInfo;
                const value = eulaSettings && eulaSettings.value;
                const eulaVersion = value && value.version;
                if (version !== eulaVersion) {
                    return initEulaPromise(extendedInitInfo);
                }
                return Promise.resolve(extendedInitInfo);
            });
    };

    const initUser = (initInfo) => {
        const {
            username,
            isAdministrator,
            generalSettings,
            userSettings,
            modeFeatureToggles,
        } = initInfo;
        AV.SystemProperties.init(modeFeatureToggles);
        return initSettings(generalSettings, userSettings || generalSettings)
            .then(() => {
                return validateLocation(isAdministrator)
                    .then((isAdminLocationValid) => {
                        if (!isAdminLocationValid) {
                            return Promise.resolve();
                        }
                        initAVUser(username, isAdministrator);
                        return import(/* webpackChunkName: "module-loader" */ '../../module-loader/main/module-loader');
                    });
            });
    };

    return initialize()
        .then(initVersionAndEula)
        .then(initInfo => Promise.all([initUser(initInfo), initSystemsStore()]))
        .catch((err) => {
            const msg = err && err.message;
            if (msg === ACCESS_DENIED_MESSAGE) {
                console.error('Access denied request iam token');
                AV.LoginProvider.endSession();
                handleAccessDeniedError();
                setTimeout(() => {
                    window.location.href = '/';
                }, 5000);
            } else {
                console.error('Layout initialization failed', err);
                handleFatalError(new Error('Module loading failed because of a backend issue.'));
                return Promise.reject(err);
            }
        });
};
/* eslint-enable consistent-return */
