/*
 * Copyright 2018, 2021 by Avid Technology, Inc.
 */
import UserSettings from '@avid-technology/ui-iam-user-settings';
import { eulaKind, eulaKey } from '../../util/config';

export default () => {
    const data = { version: window.AV.internal.version };
    return UserSettings.store(data, eulaKind, eulaKey)
        .catch((err) => {
            return Promise.reject(new Error('[Eula] Failed to save eula version', err));
        });
};
