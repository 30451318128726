/*
 * Copyright 2018-2022 by Avid Technology, Inc.
 */

import isAdminLoginCheck from '../admin/isAdminLoginCheck';
import { getBrowserWarning } from './notificationAboutUserEnvironment';
import { getValidateMessages } from '../messages';
import tryLogin from './try-login';
import localizationStorage from '../localization/storage';
import { handleFatalError } from '../../util/fatal-error';
import LoginForm from '../form/LoginForm';
import { isWindows } from './browserAndOsDetection';

const validate = ({ username, password }) => {
    let result = { isValid: true };
    if (!username || !password) {
        result = { isValid: false, message: getValidateMessages().WRONG_CREDENTIALS };
    }
    return result;
};

export const loginTypes = {
    DEFAULT: 'default',
    KERBEROS: 'kerberos',
    OKTA: 'okta',
};

const loginManager = {
    identityProviderLinks: null,
    loginPromiseResolve: null,
    initLoginForm(identityProviders) {
        return new Promise((resolve) => {
            if (!identityProviders || !identityProviders.default) {
                const message = localizationStorage.getLocalization('page-loading-error');
                console.warn('No default identity provider has been configured.');
                handleFatalError(new Error(message));
                return;
            }
            loginManager.identityProviderLinks = identityProviders;
            loginManager.loginPromiseResolve = resolve;
            this.renderForm();
        }).catch(() => {
            console.warn('Failed to get identity providers.');
            const message = localizationStorage.getLocalization('page-loading-error');
            handleFatalError(new Error(message));
        });
    },

    renderForm() {
        const isSsoWindowsEnabled = loginManager.identityProviderLinks.kerberos && isWindows;
        const isSsoOktaEnabled = loginManager.identityProviderLinks.okta;
        const oktaIssuer = loginManager.identityProviderLinks.oktaIssuer;
        this.loginForm = new LoginForm({
            isSsoWindowsEnabled,
            isSsoOktaEnabled,
            oktaIssuer,
            validateData: loginManager.validateData.bind(loginManager),
            onLogin: loginManager.onLoginCallback.bind(this),
            onLanguageChange: loginManager.onLanguageChange.bind(this),
        });
        this.loginForm.render(isAdminLoginCheck());
        this.loginForm.show();
        this.loginForm.hideLoading();
        const browserWarning = getBrowserWarning();
        if (browserWarning) {
            this.loginForm.setBrowserWarning(browserWarning);
        }
    },

    onLanguageChange(value) {
        const name = document.getElementById('name');
        const password = document.getElementById('password');
        localizationStorage.setLocale(value);
        this.loginForm?.signIn?.remove();
        this.renderForm();
        this.loginForm.focusSelectLanguageButton();
        if (name && password) {
            document.getElementById('name').value = name.value;
            document.getElementById('password').value = password.value;
        }
    },

    onLoginCallback(type, data) {
        const isAdministrator = isAdminLoginCheck();
        this.loginForm.showLoadingMessage();
        return tryLogin({
            type,
            data,
            isAdministrator,
            links: loginManager.identityProviderLinks
        })
            .then((loginResult) => {
                if (!loginResult) {
                    return Promise.reject();
                }
                if (loginResult.oktaRedirect) {
                    this.loginForm.destroy();
                    this.loginForm.renderSpinner();
                }
                if (loginResult.isValid) {
                    this.loginForm.destroy();
                    this.loginForm.renderSpinner();
                    loginManager.loginPromiseResolve();
                } else {
                    this.loginForm.hideLoading();
                    this.loginForm.showRequestMessage(loginResult.message);
                    this.loginForm.focusLoginControlForType(type);
                }
            })
            .catch((err) => {
                console.warn('Login error: ', err && err.message);
                this.loginForm.hideLoading();
                this.loginForm.showRequestError();
            });
    },

    validateData({ username, password }) {
        return validate({ username, password });
    },
};

export default loginManager;
