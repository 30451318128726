/*
 * Copyright 2018, 2022 by Avid Technology, Inc.
 */

const ACCESS_DENIED_MESSAGE = 'accessDenied';

export default (isAdmin) => {
    return new Promise((resolve, reject) => {
        const path = window.location.pathname || '/';

        if (isAdmin && path === '/') {
            window.history.pushState(window.history.state || null, window.document.title, `/admin${window.location.hash}`);
        } else if (!isAdmin && path.includes('/admin')) {
            return reject(new Error(ACCESS_DENIED_MESSAGE));
        } else if (isAdmin && !path.includes('/admin')) {
            return AV.LoginProvider.logout(path).then(() => {
                resolve(false);
            });
        }

        return resolve(true);
    });
};
