/*
 * Copyright 2021 by Avid Technology, Inc.
 */
import User from './AV.User';
import initStore from '../../systemsStore/initStore';
import { AV } from './av';

export const initAVUser = (username, isAdministrator) => {
    AV.User = new User({
        name: username,
        isAdministrator: isAdministrator,
        locale: AV.internal.userSettings.locale,
        searchQueryMode: AV.internal.userSettings.searchQueryMode,
    });
};

export const initAVBuildVersion = ({ version, build }) => {
    if (!window.AV) {
        window.AV = {};
    }
    window.AV.internal = window.AV.internal || {};
    window.AV.internal.version = version;
    window.AV.internal.build = build;
};

export const initSystemsStore = () => {
    return initStore().then(({ systems, serviceRoots }) => {
        AV.Systems = systems;
        AV.ServiceRoots = serviceRoots;
    });
};

export const setCustomerProfile = (profile) => {
    AV.internal.profile = profile;
};

export const saveIamTokenData = (iamToken) => {
    if (!window.AV) {
        window.AV = {};
    }
    window.AV.internal = window.AV.internal || {};
    window.AV.internal.masterRegion = iamToken && iamToken.masterRegion;
    window.AV.internal.user = {};
    Object.defineProperties(window.AV.internal.user, {
        contextId: {
            writable: false,
            value: iamToken && iamToken.contextId
        },
        identityId: {
            writable: false,
            value: iamToken && iamToken.identityId
        }
    });
};
