/*
 * Copyright 2021-2022 by Avid Technology, Inc.
 */
import loginTpl from '../view/login-tpl';
import { createKerberosButton, createOktaSignIn, createSsoArea } from '../view/sso-area';
import { createLanguageSelector } from '../view/language-selector/languageSelector';
import {
    el, hide, setContent, setInnerText, triggerAnimation
} from './dom-utils';
import { loginTypes } from '../main/login-manager';
import localizationStorage from '../localization/storage';
import { USER_LOCALE_KEY, WIDGET_ID } from '../../constants';
import { exchangeIdTokenForAccessToken } from './login-call';
import onLoginResponse from '../main/onLoginResponse';
import updateUserSettings from '../../settings/updateUserSettings';

export default class LoginForm {
    constructor({
        isSsoWindowsEnabled,
        oktaIssuer,
        isSsoOktaEnabled,
        validateData,
        onLogin,
        onLanguageChange
    }) {
        this.isSsoOktaEnabled = isSsoOktaEnabled;
        this.oktaIssuer = oktaIssuer;
        this.isSsoWindowsEnabled = isSsoWindowsEnabled;
        this.validateData = validateData;
        this.onLoginCallback = onLogin;
        this.onLanguageChange = onLanguageChange;

        this.nonce = new Date().getMilliseconds().toString();

        this.onSubmit = this.onSubmit.bind(this);
        this.onKerberosLogin = this.onKerberosLogin.bind(this);
        this.onOktaLogin = this.onOktaLogin.bind(this);
    }

    render(isAdmin) {
        this.destroy();
        const { container, loginControls, getters } = loginTpl(isAdmin, this.isSsoOktaEnabled);
        this.containerElem = container;
        this.getters = getters;
        document.body.append(container);
        if (this.isSsoWindowsEnabled) {
            const ssoArea = createSsoArea();
            if (this.isSsoWindowsEnabled) {
                this.kerberosButton = createKerberosButton({ onLogin: this.onKerberosLogin });
                ssoArea.append(this.kerberosButton.getElement());
            }
            loginControls.append(ssoArea.getElement());
        }
        if (!isAdmin) {
            this.languageSelector = createLanguageSelector(this.onLanguageChange);
            loginControls.appendChild(this.languageSelector.getElement());
        }
        if (this.isSsoOktaEnabled) {
            const isWidget = window.location.href.includes(WIDGET_ID);
            if (isWidget) {
                if (!this.oktaIssuer) {
                    console.error('[LoginForm][render] oktaIssuer is not provided');
                    return;
                }
                // this style is needed to fit the width of the widget to the width of the form
                document.getElementById('mcux-login-form').style = {
                    display: 'flex',
                    flexFlow: 'column',
                    alignItems: 'center'
                };

                createOktaSignIn({
                    ...this.oktaIssuer,
                    nonce: this.nonce,
                    oktaPath: this.isSsoOktaEnabled
                }).then((signIn) => {
                    this.signIn = signIn;
                    this.signIn.showSignInToGetTokens({
                        scopes: ['openid', 'profile'],
                    }).then(({ idToken: { idToken } }) => {
                        return exchangeIdTokenForAccessToken(
                            idToken,
                            this.oktaIssuer.href,
                            this.nonce,
                            this.isSsoOktaEnabled,
                        );
                    }).then(({ ok, status }) => {
                        if (!ok) {
                            throw new Error('Failed to exchange tokenId for accessToken');
                        }

                        // change language
                        const locale = localizationStorage.getLocale();
                        localStorage.setItem(USER_LOCALE_KEY, locale);
                        updateUserSettings({ locale });

                        // verify if user has permissions
                        return onLoginResponse({
                            response: { status },
                            isAdministrator: isAdmin
                        });
                    }).then(({ isValid, message }) => {
                        if (!isValid) {
                            this.signIn.authClient.tokenManager.clear();
                            this.signIn.remove();
                            this.showRequestMessage(message);
                            return;
                        }

                        // remove #widget from the hash
                        const hash = window.location.hash.replace(WIDGET_ID, '');
                        // update url
                        window.location.href = `${window.location.origin}${hash}`;
                        // reload the page for the limited mode
                        if (hash) {
                            window.location.reload();
                        }
                    })
                        .catch((err) => {
                            console.error('[LoginForm][render] failed to create Okta widget', err);
                            this.signIn.authClient.tokenManager.clear();
                            this.showRequestError();
                        });
                });
            } else {
                this.getters.oktaButton().addEventListener('click', this.onOktaLogin);
                this.getters.oktaButton().focus();
                if (window.AV.internal.getShowCredentialsAreaToggle()) {
                    this.getters.form().addEventListener('submit', this.onSubmit);
                    this.getters.nameInput().focus();
                }
            }
        } else {
            this.getters.form().addEventListener('submit', this.onSubmit);
            this.getters.nameInput().focus();
        }
    }

    destroy() {
        if (!this.containerElem) {
            document.body.innerHTML = '';
            return;
        }

        if (this.getters.form()) {
            this.getters.form().removeEventListener('submit', this.onSubmit);
        }

        if (this.getters.oktaButton()) {
            this.getters.oktaButton().removeEventListener('click', this.onOktaLogin);
        }

        if (this.languageSelector) {
            this.languageSelector.destroy();
            this.languageSelector = null;
        }

        if (this.kerberosButton) {
            this.kerberosButton.destroy();
            this.kerberosButton = null;
        }

        this.containerElem.remove();
        this.containerElem = null;

        document.body.innerHTML = '';
    }

    onSubmit(event) {
        event.preventDefault();
        this.cleanError();
        const formData = {
            username: this.getName(),
            password: this.getPassword(),
        };
        const result = this.validateData(formData);
        if (!result.isValid) {
            this.showError(result.message);
            this.getters.nameInput().focus();
            return;
        }
        this.onLoginCallback(loginTypes.DEFAULT, formData);
    }

    onKerberosLogin() {
        this.onLoginCallback(loginTypes.KERBEROS);
    }

    onOktaLogin(event) {
        if (event) event.preventDefault();
        this.onLoginCallback(loginTypes.OKTA);
    }

    focusLoginControlForType(type) {
        if (type === loginTypes.DEFAULT) {
            this.getters.nameInput().focus();
        } else if (type === loginTypes.KERBEROS) {
            this.kerberosButton.focus();
        } else if (type === loginTypes.OKTA) {
            this.getters.oktaButton().focus();
        }
    }

    focusSelectLanguageButton() {
        this.languageSelector.focus();
    }

    showRequestError() {
        const msg = localizationStorage.getLocalization('page-loading-error');
        setInnerText(el('mcux-login-loading-inner'), msg);
        el('mcux-login-loading-outer').style.display = 'flex';
        el('mcux-login-controls').style.visibility = 'hidden';
    }

    showLoadingMessage() {
        const msg = '...';
        setInnerText(el('mcux-login-loading-inner'), msg);
        el('mcux-login-loading-outer').style.display = 'flex';
        el('mcux-login-controls').style.visibility = 'hidden';
    }

    cleanError() {
        hide(el('mcux-login-error'));
    }

    showError(str) {
        const errEl = el('mcux-login-error');
        setContent(errEl, str);
        triggerAnimation(errEl);
    }

    showRequestMessage(msg) {
        this.show();
        if (msg) {
            this.showError(msg);
        } else {
            this.hideLoading();
            this.showError();
        }
    }

    setBrowserWarning(browserWarning) {
        setInnerText(el('mcux-login-browser-message'), browserWarning);
        el('mcux-login-browser-notice').style.display = 'block';
    }

    hideLoading() {
        el('mcux-login-loading-outer').style.display = 'none';
    }

    show() {
        el('mcux-login-controls').style.visibility = 'visible';
    }

    getName() {
        return this.getters.nameInput().value;
    }

    getPassword() {
        return el('password').value;
    }

    renderSpinner() {
        const spinner = document.createElement('div');
        spinner.setAttribute('id', 'avid-nux-page-load-indicator');
        spinner.setAttribute('class', 'nux-loading-spinner-large');
        document.body.appendChild(spinner);
    }
}
