/*
 * Copyright 2018 by Avid Technology, Inc.
 */
import languages from '../../../configs/languages.json';
import dateStyles from '../../../configs/dateStyles';

const checkLanguageSupport = (languageToCheck) => {
    const language = languages.find(language => language.value === languageToCheck);
    return Boolean(language && language.value);
};

const checkDateStyleSupport = (dateStyleToCheck) => {
    const keys = Object.keys(dateStyles);
    const dateStyleKey = keys.find(key => key === dateStyleToCheck);
    return Boolean(dateStyleKey);
};

const checkTimeZoneSupport = (timeZoneNames, timeZoneToCheck) => {
    const isCorrectTimeZone = timeZoneNames && timeZoneNames.some(name => name === timeZoneToCheck);
    return Boolean(isCorrectTimeZone);
};

export {
    checkLanguageSupport,
    checkDateStyleSupport,
    checkTimeZoneSupport
};
