/*
 * Copyright 2013-2018, 2021-2022 by Avid Technology, Inc.
 */

import { ADMIN_LOCALE } from '../../../configs/admin-locale';

export default function (config) {
    if (!config.name) {
        throw new Error('name property must be present in config');
    }

    if (!config.locale) {
        throw new Error('locale property must be present in config');
    }

    const readOnly = function (param) {
        return {
            value: param,
            writable: false,
            enumerable: true
        };
    };

    return Object.create({}, {
        name: readOnly(config.name),
        searchQueryMode: {
            value: config.searchQueryMode,
            writable: true,
            enumerable: true
        },
        isAdministrator: {
            get() {
                return config.isAdministrator;
            }
        },
        locale: config.isAdministrator ? readOnly(ADMIN_LOCALE) : readOnly(config.locale)
    });
}

