/*
 * Copyright 2020-2021 by Avid Technology, Inc.
 */

export default function createStylesElement({ el = 'div', classNames = [], id }) {
    const element = document.createElement(el);
    element.classList.add(...classNames);
    if (id) {
        element.setAttribute('id', id);
    }
    return element;
}
