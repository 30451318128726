/*
 * Copyright 2019 by Avid Technology, Inc.
 */

import fetchServiceRoots from './fetchServiceRoots';

const DEFAULT_INTERVAL = 1000 * 30;

export default class SystemsStore {
    constructor() {
        this.systems = [];
        this.serviceRoots = {};
        this.started = false;
        this.interval = -1;
    }

    getSystem({ systemType, systemID }) {
        return this.systems
            .find(s => s.systemID === systemID && s.systemType === systemType);
    }

    exists({ systemType, systemID }) {
        const system = this.getSystem({ systemType, systemID });
        return Boolean(system);
    }

    isRemote({ systemType, systemID }) {
        const system = this.getSystem({ systemType, systemID });
        return Boolean(system && system.origin);
    }

    getOrigin({ systemType, systemID }) {
        const system = this.getSystem({ systemType, systemID });
        return system && system.origin ? system.origin : null;
    }

    getUrl({ systemType, systemID, url }) {
        const system = this.getSystem({ systemType, systemID });

        if (!system) {
            return null;
        }
        const { origin } = system;

        if (origin && url) {
            return `${origin}${url}`;
        } else if (origin && !url) {
            return origin;
        } else if (!origin && url) {
            return url;
        }

        return null;
    }

    getResourceLink({ systemType, systemID, link }) {
        const system = this.getSystem({ systemType, systemID });

        if (!system) {
            return null;
        }
        const resource = system._links && system._links[link];
        if (!resource) {
            return null;
        }
        return resource.href;
    }

    getServiceRoots() {
        return this.serviceRoots;
    }

    onError(error) {
        console.error(`[SystemsStore] Error while fetching serviceRoots, error: ${error}`);
        return Promise.resolve();
    }

    onSuccess({ systems, serviceRoots }) {
        this.updateSystems(systems);
        this.serviceRoots = serviceRoots;
        const interval = DEFAULT_INTERVAL;

        if (interval !== -1 && this.interval !== interval) {
            this.interval = interval;
            this.stopInterval();
            this.setupInterval();
        }

        return Promise.resolve();
    }

    loadSystems() {
        return fetchServiceRoots()
            .then(this.onSuccess.bind(this))
            .catch(this.onError.bind(this));
    }

    isRunning() {
        return this.started || this.handle !== undefined;
    }

    run() {
        if (!this.isRunning()) {
            this.started = true;
            return this.loadSystems();
        }
    }

    stop() {
        this.stopInterval();
        this.interval = -1;
        this.started = false;
    }

    updateSystems(systems) {
        this.systems = systems;
    }

    setupInterval() {
        if (this.started) {
            this.handle = window.setInterval(this.loadSystems.bind(this), this.interval);
        }
    }

    stopInterval() {
        if (this.handle) {
            window.clearInterval(this.handle);
            delete this.handle;
        }
    }
}
