/*
 * Copyright 2021 by Avid Technology, Inc.
 */
import { USER_LOCALE_KEY } from '../../constants';
import { ADMIN_LOCALE } from '../../../configs/admin-locale';
import languages from '../../../../configs/languages.json';
import localization from './localization';

const defaultLocale = 'en';

const isExistingLocale = (locale) => {
    const locales = languages.map(lang => lang.value);
    return locales.includes(locale);
};

const sanitize = (locale) => {
    return isExistingLocale(locale) ? locale : defaultLocale;
};

export function formatString(str, ...param) {
    const formatRe = /{(\d{1,3})}/g;
    return str.replace(formatRe, (m, i) => {
        return param[i];
    });
}

export default class LanguageStorage {
    constructor({ isAdmin }) {
        this.isAdmin = isAdmin;
        if (isAdmin) {
            this.locale = ADMIN_LOCALE;
        } else {
            this.locale = sanitize(localStorage.getItem(USER_LOCALE_KEY));
        }
    }

    getLocale() {
        return this.locale;
    }

    setLocale(locale) {
        if (this.isAdmin) {
            return;
        }
        this.locale = sanitize(locale);
    }

    getLocalization(key, ...param) {
        const requiredLocale = this.getLocale();
        const localeData = localization[requiredLocale] || localization[defaultLocale];
        const requiredString = localeData[key] || localization[defaultLocale][key];
        return formatString(requiredString, ...param);
    }
}
