/*
 * Copyright 2018-2021, 2022 by Avid Technology, Inc.
 */

import { v4 as uuid } from 'uuid';
import styles from './styles/login-tpl.scss';
import redirect from '../main/redirect';
import { customerLogoUrl } from '../../../configs/logoUrl';
import localizationStorage from '../localization/storage';
import createStylesElement from './createStylesElement';
import { getLoginBackground, getTitle, createLoginArea } from './profiles';
import { WIDGET_ID } from '../../constants';

function createDiv() {
    return document.createElement('div');
}

function createCustomerLogo() {
    const customerLogoContainer = createDiv();
    customerLogoContainer.classList.add(styles['customer-logo-container']);
    customerLogoContainer.setAttribute('id', 'customer-logo-container');

    const logoImage = document.createElement('img');
    logoImage.src = customerLogoUrl;
    logoImage.alt = 'logo';
    logoImage.classList.add(styles['customer-logo']);
    logoImage.onerror = () => {
        document.getElementById('customer-logo-container').classList.add(styles.hidden);
    };
    customerLogoContainer.appendChild(logoImage);
    return customerLogoContainer;
}

function createBrowserNotice() {
    const loginBrowserNotice = createStylesElement({
        classNames: ['mcux-login-browser-notice', styles['mcux-login-browser-notice']],
        id: 'mcux-login-browser-notice',
    });
    loginBrowserNotice.style.display = 'none';

    const loginNoticeMessage = createStylesElement({
        el: 'p',
        classNames: ['mcux-login-browser-message', styles['mcux-login-browser-message']],
        id: 'mcux-login-browser-message',
    });
    loginNoticeMessage.innerText = '';

    const supportedBrowsersLabel = document.createElement('p');
    supportedBrowsersLabel.innerText = localizationStorage.getLocalization('supported-browsers-and-systems-label');

    const supportedBrowsersList = document.createElement('ul');
    const supportedBrowserElement = document.createElement('li');
    supportedBrowserElement.innerText = localizationStorage.getLocalization('supported-browsers-and-systems-list');
    supportedBrowsersList.appendChild(supportedBrowserElement);

    loginBrowserNotice.appendChild(loginNoticeMessage);
    loginBrowserNotice.appendChild(supportedBrowsersLabel);
    loginBrowserNotice.appendChild(supportedBrowsersList);

    return loginBrowserNotice;
}

function createIcon(id) {
    const iconContainer = createStylesElement({
        classNames: ['mcux-login-icon-container', styles['mcux-login-icon-container']],
    });

    const userNameIcon = createStylesElement({
        el: 'i',
        classNames: ['mcux-login-icon', styles['mcux-login-icon'], styles[id]],
        id,
    });

    iconContainer.appendChild(userNameIcon);
    return iconContainer;
}

function createUserNameSection() {
    const formSectionUserNameContainer = createStylesElement({
        el: 'p',
        classNames: ['mcux-paragraph', styles['mcux-paragraph']],
    });

    const userNameInput = createStylesElement({
        el: 'input',
        classNames: ['mcux-login-textbox', styles['mcux-login-textbox']],
        id: 'name',
    });
    userNameInput.type = 'text';
    userNameInput.name = 'name';
    userNameInput.placeholder = localizationStorage.getLocalization('username-placeholder');
    userNameInput.autoComplete = 'off';
    userNameInput.spellcheck = false;
    userNameInput.autoFocus = true;

    formSectionUserNameContainer.appendChild(createIcon('mcux-login-icon-username'));
    formSectionUserNameContainer.appendChild(userNameInput);

    return formSectionUserNameContainer;
}

function createPasswordSection() {
    const formSectionUserPasswordContainer = createStylesElement({
        el: 'p',
        classNames: ['mcux-paragraph', styles['mcux-paragraph']],
    });

    const userPasswordInput = createStylesElement({
        el: 'input',
        classNames: ['mcux-login-textbox', styles['mcux-login-textbox']],
        id: 'password',
    });
    userPasswordInput.type = 'password';
    userPasswordInput.name = 'password';
    userPasswordInput.placeholder = localizationStorage.getLocalization('password-placeholder');
    userPasswordInput.autoComplete = 'off';

    formSectionUserPasswordContainer.appendChild(createIcon('mcux-login-icon-password'));
    formSectionUserPasswordContainer.appendChild(userPasswordInput);

    return formSectionUserPasswordContainer;
}

function createSubmitButton() {
    const submitButtonContainer = createStylesElement({
        el: 'p',
        classNames: ['mcux-paragraph', styles['mcux-paragraph']],
    });

    const submitButton = createStylesElement({
        el: 'input',
        classNames: ['mcux-submit-button', styles['mcux-submit-button']],
        id: 'loginButton',
    });

    submitButton.type = 'submit';
    submitButton.value = localizationStorage.getLocalization('sign-in-button');

    submitButtonContainer.appendChild(submitButton);
    return submitButtonContainer;
}

function createOktaButton() {
    const oktaButton = createStylesElement({
        el: 'button',
        id: 'okta-button',
        classNames: [styles['okta-button']]
    });
    oktaButton.innerText = localizationStorage.getLocalization('okta-sign-in-label');

    return oktaButton;
}

function createForm(isAdmin, isSsoOktaEnabled) {
    const form = createStylesElement({
        el: 'form',
        classNames: ['mcux-login-form', styles['mcux-login-form']],
        id: 'mcux-login-form',
    });

    const formAdminHeader = createStylesElement({
        el: 'p',
        classNames: ['mcux-login-admin-header', styles['mcux-login-admin-header']],
        id: 'login-admin-header',
    });

    formAdminHeader.style.display = isAdmin ? 'block' : 'none';
    formAdminHeader.innerText = localizationStorage.getLocalization('administrator-role');

    const loginError = createStylesElement({
        el: 'p',
        classNames: ['mcux-login-error', styles['mcux-login-error']],
        id: 'mcux-login-error',
    });
    loginError.style.display = 'none';
    loginError.innerText = '';
    loginError.setAttribute('aria-live', 'assertive');

    const oktaButton = createOktaButton();

    form.appendChild(formAdminHeader);
    form.appendChild(createBrowserNotice());
    form.appendChild(loginError);

    const isWidget = window.location.href.includes(WIDGET_ID);

    if (isSsoOktaEnabled && !isWidget) {
        if (window.AV.internal.getShowCredentialsAreaToggle()) {
            form.appendChild(createUserNameSection());
            form.appendChild(createPasswordSection());
            form.appendChild(createSubmitButton());
        }
        form.appendChild(oktaButton);
    } else if (!isSsoOktaEnabled) {
        form.appendChild(createUserNameSection());
        form.appendChild(createPasswordSection());
        form.appendChild(createSubmitButton());
    }

    return form;
}

function createLogInArea(isAdmin, isSsoOktaEnabled) {
    const mcuxArea = createLoginArea();

    const title = getTitle();

    const loginLoadingOuter = createStylesElement({
        classNames: ['mcux-loading-outer', styles['mcux-loading-outer']],
        id: 'mcux-login-loading-outer',
    });

    const loginLoadingInner = createStylesElement({
        classNames: ['mcux-loading-inner', styles['mcux-loading-inner']],
        id: 'mcux-login-loading-inner',
    });

    loginLoadingOuter.appendChild(loginLoadingInner);
    const loginControls = createStylesElement({
        id: 'mcux-login-controls',
        classNames: [styles['login-controls']]
    });
    const form = createForm(isAdmin, isSsoOktaEnabled);
    loginControls.append(form);
    mcuxArea.appendChild(loginLoadingOuter);
    mcuxArea.appendChild(title);
    mcuxArea.appendChild(loginControls);

    return { loginArea: mcuxArea, loginControls };
}

function createBackground(isAdmin) {
    const background = getLoginBackground();

    if (isAdmin) {
        const adminBg = createDiv();
        adminBg.classList.add(styles['admin-login-page']);
        background.appendChild(adminBg);
    }

    document.body.appendChild(background);
}

export default (isAdmin, isSsoOktaEnabled) => {
    createBackground(isAdmin);
    const container = createDiv();
    container.classList.add(styles['login-page']);
    container.setAttribute('id', 'login-page');

    const logo = createStylesElement({
        classNames: [styles.logo],
        id: `logo-${uuid()}`,
    });
    logo.onclick = redirect;

    const customerLogo = createCustomerLogo();

    const loginContainerForm = createStylesElement({
        classNames: ['form-container', styles['form-container']],
    });

    const { loginArea, loginControls } = createLogInArea(isAdmin, isSsoOktaEnabled);
    loginContainerForm.appendChild(loginArea);

    container.appendChild(logo);
    container.appendChild(customerLogo);
    container.appendChild(loginContainerForm);

    return {
        container,
        loginControls,
        getters: {
            nameInput: () => {
                return document.getElementById('name');
            },
            form: () => {
                return document.getElementById('mcux-login-form');
            },
            oktaButton: () => {
                return document.getElementById('okta-button');
            },
        }
    };
};
