/*
 * Copyright 2018, 2021 by Avid Technology, Inc.
 */

import { checkLanguageSupport, checkDateStyleSupport } from '../util/settingsValidation';
import defaultSettings from '../../../configs/defaultSettings';

const defineGeneralSystemSettingsProperties = (key, value) => {
    Object.defineProperty(AV.internal.generalSystemSettings, key, {
        value,
        writable: false,
        enumerable: true
    });
};

export default (settings = {}) => {
    AV.internal.generalSystemSettings = {};
    const keys = Object.keys(settings);
    if (!keys.includes('language')) {
        keys.push('language');
    }
    if (!keys.includes('dateFormat')) {
        keys.push('dateFormat');
    }
    keys.forEach((key) => {
        let value = settings[key];
        if (key === 'language' && !checkLanguageSupport(settings.language)) {
            value = defaultSettings.LOCALE;
        } else if (key === 'dateFormat' && !checkDateStyleSupport(settings.dateFormat)) {
            value = defaultSettings.DATE_STYLE;
        }
        defineGeneralSystemSettingsProperties(key, value);
    });
};
