/*
 * Copyright 2021 by Avid Technology, Inc.
 */

export function el(id) {
    return document.getElementById(id);
}

export function setInnerText(el, text) {
    if (el.firstChild) {
        el.replaceChild(document.createTextNode(text), el.firstChild);
    } else {
        el.appendChild(document.createTextNode(text));
    }
}

export function setContent(el, str) {
    el.innerText = str;
    el.style.display = 'block';
}

export function hide(el) {
    el.innerText = '';
    el.style.display = 'none';
}

export function triggerAnimation(el) {
    const anim1 = 'mcux-login-error-anim-1';
    const anim2 = 'mcux-login-error-anim-2';
    let nextAnim = anim1;

    if (el.classList.contains(anim1)) {
        el.classList.remove(anim1);
        nextAnim = anim2;
    }

    if (el.classList.contains(anim2)) {
        el.classList.remove(anim2);
        nextAnim = anim1;
    }

    el.classList.add(nextAnim);
}
