/*
 * Copyright 2018-2020 by Avid Technology, Inc.
 */
/**
 * @author anna.kryzhko
 */

import getEmbeddedInMode from '../../../util/getEmbeddedInMode';

export default () => {
    const embeddedInMode = getEmbeddedInMode();
    if (embeddedInMode) {
        return embeddedInMode;
    }
    if (!window.AV || !window.AV.User) {
        return { mode: null };
    }
    if (window.AV.User.isAdministrator) {
        return { mode: 'admin' };
    }
    return { mode: 'main' };
};
