/*
 * Copyright 2018 by Avid Technology, Inc.
 */

export default () => {
    return fetch('/auth/tokens/current', {
        method: 'delete',
        credentials: 'same-origin',
        headers: {
            Accept: 'application/json',
        },
    }).then((response) => {
        if (response.status >= 200 && response.status < 300) {
            console.log('Logout is successful');
            return;
        }
        console.warn('Logout is failed, force manual logout', response);
        throw new Error('Request error');
    });
};
