/*
 * Copyright 2022 by Avid Technology, Inc.
 */

import { WIDGET_ID } from '../constants';

export default function getHashFromURL() {
    const { hash } = window.location;
    const [, result] = hash.replace(WIDGET_ID, '').split('#');
    return result ? `#${result}` : '';
}
