/*
 * Copyright 2018-2022 by Avid Technology, Inc.
 */

import getFailureMessage from './getFailureMessage';
import '../../module-loader/main/system-properties/SystemProperties';
import getInitConfiguration from '../../util/getInitConfiguration';
import localizationStorage from '../localization/storage';

const getJson = (response) => {
    return Promise.resolve().then(() => {
        return response.json();
    }).catch((err) => {
        console.warn('Error with parsing login result:', err);
        throw new Error('Unexpected response');
    });
};

export default ({ response, isAdministrator }) => {
    const { status } = response;
    const result = {
        isValid: false,
        message: ''
    };
    const licenseError = () => {
        AV.LoginProvider.endSession();
        result.message = localizationStorage.getLocalization('entitlement-check-error');
        return result;
    };

    if (status === 200) {
        if (!isAdministrator) {
            return getInitConfiguration()
                .then(({ hasPermissions }) => {
                    result.isValid = hasPermissions;
                    if (!hasPermissions) {
                        return licenseError();
                    }
                    return result;
                })
                .catch((err) => {
                    const { status: statusCode } = err;
                    if (statusCode === 403) {
                        return licenseError();
                    }
                    throw err;
                });
        }
        result.isValid = true;
        return result;
    }
    return getJson(response).then((data) => {
        const msg = getFailureMessage(status, data);
        result.message = msg || '';
        return result;
    });
};

export const onOktaLoginResponse = ({ response }) => {
    const { status } = response;
    if (status === 200) {
        return getJson(response)
            .then((redirectData) => {
                window.location.assign(redirectData.location);
                return Promise.resolve({ oktaRedirect: true });
            });
    }
    return getJson(response).then((data) => {
        const msg = getFailureMessage(status, data);
        return {
            isValid: false,
            message: msg || '',
        };
    });
};
