/*
 * Copyright 2018, 2021 by Avid Technology, Inc.
 */
import { get } from './http-requests';
import { getSettingsPath } from './path';

export default (kind, key) => {
    const url = getSettingsPath(kind, key);
    return get(url);
};
