/*
 * Copyright 2021 by Avid Technology, Inc.
 */

import { brandingProfiles } from '../../constants';

export default () => {
    const defaultTitle = 'Avid MediaCentral | Cloud UX';
    const mcemTitle = 'Avid NEXIS | EDGE';
    const isMcemProfile = window.AV.internal.profile === brandingProfiles.mcem;

    document.title = isMcemProfile ? mcemTitle : defaultTitle;

    let iconLink = document.querySelector("link[rel~='icon']");
    if (!iconLink) {
        iconLink = document.createElement('link');
        iconLink.rel = 'icon';
        iconLink.href = isMcemProfile ? '/core/images/mcem-logo.svg' : '/core/images/icon.png';
        document.getElementsByTagName('head')[0].appendChild(iconLink);
    }
};
