/*
 * Copyright 2020-2021 by Avid Technology, Inc.
 */
import UserSettings from '@avid-technology/ui-iam-user-settings';
import { key, kind } from '../../../configs/userSettings';

export default (settings) => {
    return UserSettings.store(settings, kind, key)
        .then(({ entity }) => {
            return (entity && entity.value) || {};
        });
};
