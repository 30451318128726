/*
 * Copyright 2018, 2021 by Avid Technology, Inc.
 */
import login, { kerberosLogin, oktaLogin } from '../form/login-call';
import onLoginResponse, { onOktaLoginResponse } from './onLoginResponse';
import { loginTypes } from './login-manager';

export default ({ type, data, isAdministrator, links }) => {
    if (type === loginTypes.DEFAULT) {
        return login({
            username: data.username,
            password: data.password,
            link: links[type],
            isAdminLogin: isAdministrator
        }).then((response) => {
            return onLoginResponse({ response, isAdministrator });
        });
    } else if (type === loginTypes.KERBEROS) {
        return kerberosLogin({
            link: links[type],
            isAdminLogin: isAdministrator
        }).then((response) => {
            return onLoginResponse({ response, isAdministrator });
        });
    } else if (type === loginTypes.OKTA) {
        return oktaLogin({
            link: links[type],
            isAdminLogin: isAdministrator
        }).then((response) => {
            return onOktaLoginResponse({ response });
        });
    }
    return Promise.reject();
};
