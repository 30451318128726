/*
 * Copyright 2019-2020 by Avid Technology, Inc.
 */

import { Registry } from '@avid-technology/ui-ctms-provider';

export default () => {
    return Registry.getServiceRoots({ forceRefresh: true })
        .then((serviceRoots) => {
            return {
                systems: Registry.extractSystems(serviceRoots),
                serviceRoots
            };
        });
};
