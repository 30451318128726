/*
 * Copyright 2018 by Avid Technology, Inc.
 */

/**
 * User settings iam principal settings kind and key config.
 */

export const kind = 'nux-ui-settings';
export const key = 'user-settings';

