/*
 * Copyright 2018, 2021 by Avid Technology, Inc.
 */

import eulaFormView from '../form/eula-form';
import saveEula from '../data-provider/save-eula';
import getLicenseText from '../data-provider/get-license-text';

const eulaManager = {
    initEulaForm(extendedInitInfo) {
        eulaFormView.render();
        return new Promise((resolve) => {
            return getLicenseText()
                .then((text) => {
                    eulaFormView.show();
                    eulaFormView.addAgreementText(text || '');

                    eulaFormView.onSubmit((event) => {
                        event.preventDefault();
                        eulaFormView.showLoading();
                        return eulaManager.saveEulaHandler(resolve, extendedInitInfo);
                    });
                    eulaManager.addGlobalEvents();
                })
                .catch((err) => {
                    console.warn('[EULA] Eula page initialize failed.', err);
                    eulaFormView.renderRequestError();
                });
        });
    },

    saveEulaHandler(resolve, extendedInitInfo) {
        return saveEula()
            .then(() => {
                eulaFormView.destroy();
                eulaFormView.renderSpinner();
                eulaManager.removeGlobalEvents();
                resolve(extendedInitInfo);
            })
            .catch(eulaFormView.renderRequestError);
    },

    addGlobalEvents() {
        document.addEventListener('keydown', (event) => {
            eulaManager.globalKeyDownHandler(event);
        });
    },

    removeGlobalEvents() {
        document.removeEventListener('keydown', (event) => {
            eulaManager.globalKeyDownHandler(event);
        });
    },

    globalKeyDownHandler(event) {
        const leftArrowKeyCode = 37;
        if (event.altKey && event.keyCode === leftArrowKeyCode) {
            event.preventDefault();
            AV.LoginProvider.endSession()
                .then(() => {
                    location.reload();
                }).catch((err) => {
                    console.warn('[Eula page] Logout was failed! Err: ', err);
                    location.reload();
                });
        }
    }
};

export default eulaManager;
