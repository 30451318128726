/*
 * Copyright 2018 by Avid Technology, Inc.
 */
const dateStyles = {
    DMY: "DD.MM.YYYY HH:mm:ss Z",
    "DMY-slash": "DD/MM/YYYY HH:mm:ss Z",
    YMD: "YYYY-MM-DD HH:mm:ss Z",
    MDY: "MM/DD/YYYY hh:mm:ss A Z"
};

export default dateStyles;
